import store from "../../store";

import { actions } from './redux.login';
import GraphqlClient, { SetClientJwt } from "../../client/graphql.client";
import { SetPermissions } from "./authorizer";
import { getSSOToken } from "src/material/AuthGuard";
import { updateLoginToken } from "src/graphql/generated/mutations";

export const refreshToken = async (accessToken) => {
    store.dispatch(actions.refreshingToken());
    try {
        const res = await GraphqlClient.getNewAccessToken({ refreshToken: accessToken })
        await SetPermissions(res.getNewAccessToken.permissions);
        SetClientJwt(res.getNewAccessToken.accessToken)

        store.dispatch(actions.refreshedToken(res.getNewAccessToken));
    } catch (e) {
        console.error(e);
        store.dispatch(actions.cannotRefreshToken());
    }
}

export const resetPassword = async (response: any) => {
    await SetPermissions(response.permissions);
    SetClientJwt(response.accessToken)

    store.dispatch(actions.refreshedToken(response));
}

export const ssoValidateQuery = async (ssoToken: string) => {
    try {
        const res = await GraphqlClient.validateSSOToken({ ssoToken })
        // await SetPermissions(res.ssoLogin.permissions);
        SetClientJwt(res.validateSSOToken.accessToken)

        store.dispatch(actions.login(res.validateSSOToken));
        return { logged: true }
    } catch (error) {
        console.error(error)
    }
}

export const login = async (username, password) => {
    try {
        const res = await GraphqlClient.basicLogin({ username, password });
        await SetPermissions(res.basicLogin.permissions);
        SetClientJwt(res.basicLogin.accessToken)

        store.dispatch(actions.login(res.basicLogin));
        return { logged: true }
    } catch (error) {
        return { logged: false, message: JSON.parse(JSON.stringify(error)).response.error }
    }
};

export const loginSSO = async (code) => {
    try {
        const res = await GraphqlClient.updateLoginToken({code})
        const token = atob(res.updateLoginToken.token)
        let parsed = JSON.parse(token)
        await SetPermissions(parsed.permissions);
        SetClientJwt(parsed.accessToken)

        store.dispatch(actions.login(parsed));
        return { logged: true, message: 'ok' }
    } catch (error) {
        return { logged: false, message: error }
    }
};

export const impersonificate = async (userId: string) => {
    try {
        const res = await GraphqlClient.impersonates({ email: userId });
        await SetPermissions(res.impersonates.permissions);
        SetClientJwt(res.impersonates.accessToken)

        store.dispatch(actions.impersonates(res.impersonates));
        return { data: res.impersonates }
    } catch (error) {
        return { error: true, message: error.toString() }
    }
}

export const removeImpersonation = async () => {
    await SetPermissions(store.getState().auth.userPreImpersonation.permissions);
    SetClientJwt(store.getState().auth.userPreImpersonation.accessToken)

    store.dispatch(actions.removeImpersonation());
}


export const logout = async () => {
    SetClientJwt("");
    store.dispatch(actions.logout())
    window.location.replace("/slo")
}

export const setAvatar = (avatar) => {
    store.dispatch(actions.setAvatar(avatar))
}
