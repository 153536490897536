import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

const resources = {
    it: {
        translation: {
            "user":"Utente",
            'loginHeader': 'Benvenuto',
            'loginDescription': 'Accedi alla piattaforma',
            'loginBtn': 'Accedi',
            'logout': 'Disconnetti',
            "recoveryHeader": "Recupero Password",
            "recoveryDescription": "A quale email vuoi ricevere le istruzioni per il recupero?",
            "proceed": "Procedi",
            "emailAddress": "Indirizzo Email",
            'demoLabel': 'Utilizzare',
            'forgotPwd': 'Password dimenticata',
            "requiredField": "Campo obbligatorio",
            "notValidEmail": "Email non valida",
            "profile": "Account",
            "accountGeneral": "Generale",
            "accountSecurity": "Sicurezza",
            "setAvatar": "Imposta avatar",
            "removeAvatar": "Rimuovi avatar",
            "profileT": "Profilo",

            "firstName": "Nome",
            "lastName": "Cognome",
            "save": "Salva",

            "changePwd": "Cambia password",
            "oldPwd": "Vecchia password",
            "password": "Password",
            "confirmPassword": "Conferma password",
            "pwdMismatch": "Le password devono coincidere",
            "pwdUpdated": "Password aggiornata con successo",
            "minLen7": "Lunghezza minima: 7 caratteri",

            "newUser": "Nuovo utente",
            "user_breadcrumb": "Nuovo utente",
            "usersT": "Utenti",
            "newUserTitle": "Informazioni login",
            "newUserTitle2": "Anagrafica",

            /*SETTINGS */
            "theme": "Tema",
            "DARK": "Scuro",
            "LIGHT": "Chiaro",
            "NATURE": "Natura",
            "settings": "Impostazioni",
            "saveSettings": "Salva Impostazioni",
            "rtl": "Cambia direzione testo",
            "responsiveFont": "Grandezza font dinamica",
            "responsiveFontDescription": "Dimensione font responsive",
            "compact": "Compatto",
            "compactDescription": "Larghezza fissa in alcune risoluzioni",
            "roundBorders": "Bordi rotondi",
            "roundBordersDescription": "Aumenta il raggio dei bordi",

            /* GENERAL */
            "back": "Indietro",
            "confirm": "Conferma",
            "actions": "Azioni",
            "action": "Azione",
            "by": "Da",
            "at": "Data",
            "open": "Aperto",
            "closed": "Chiuso",
            "acknowledge": "Accetta",
            "idle": "Stallo",
            "details": "Dettagli",
            "closeIt": "Chiudi",
            "openIt": "Apri",
            "delete": "Elimina",
            "cancel": "Annulla",
            "warning": "Avviso",
            "new": "Nuova",
            "add": "Aggiungi",
            "logs": "Logs",
            "waiting": "In Attesa",
            "notAssigned": "Non Assegnato",
            "errorComments": "Errore durante il caricamento dei commenti",

            /* DIALOG */
            "definitiveAction": "Questa è un'azione irreversibile, vuoi continuare?",

            /* NOTIFICATIONS */
            "error": "Errore",
            "success": "Confermato",

            /* SIDEBAR */
            "users": "Utenti",
            "list": "Lista",
            "roles": "Ruoli e Permessi",

            /* YEOMAN IT ANCHOR */
            "loginSSO": "Accedi con Allianz SSO",
            "rolesAndPerms": "Ruoli e Permessi",
            "tables": "Tabelle",
            "general": "Generale",

            /*ROLES & PERMS*/
            "role":"Ruolo",
            "policy":"Policy",
            "resource":"Risorsa",
            "subject":"Soggetto",
            "domain":"Dominio",
            "groups":"Gruppi",
            "newRole":"Nuovo Ruolo",
            "roleInfo":"Informazioni Ruolo",
            "newPolicy":"Nuova Policy",
            "newGroup":"Nuovo Gruppo",
            "policySettings":"Impostazioni Policy",
            "policySettings1":"Impostazioni Policy",
            "roleCreated":"Ruolo Creato",
            "errorLoadingPolicy":"Errore durante il caricamento delle policy",
            "errorLoadingGroups":"Errore durante il caricamento dei gruppi",
            "policyCreated":"Policy creata",
            "policyInfo":"Informazioni Policy",
            "groupCreated":"Gruppo creato",
            "groupInfo":"Informazioni Gruppo",
            "allow":"Permetti",
            "deny":"Nega",

            /* DATE FILTER */
            "startDate": "Inizio",
            "endDate": "Fine",
            "today": "Oggi",
            "yesterday": "Ieri",
            "tomorrow": "Domani",
            "week": "Settimana",
            "month": "Mese",
            "lastMonth": "Mese Scorso",
            "nextMonth": "Mese Prossimo",

            /* GUEST REPORT */
            "originalReport": "Segnalazione Originale",
            "currentStatus": "Status Corrente",
            "lastFollowup": "Ultimo Follow-Up",

            /* TABLE */
            "of": "di",
            "tableNoData": "Nessun dato trovato",
            "select": "Seleziona",
            "memoFilters": "Memorizza Filtri",
            "resetFilters": "Reset Filtri",
            "view": "Visualizza",
            "page": "Pagina",

            /* YEOMAN IT ANCHOR */
            "reports": "Segnalazioni",
            "report": "Segnalazione",
            "state": "Stato",
            "assigned": "Assegnato a",
            "createdAt": "Creato Il",
            "createdBy": "Creato Da",
            "updatedAt": "Modificato Il",
            "updatedBy": "Modificato Da",
            "receivedAt": "Ricevuto il",
            "attachments": "Allegati",
            "newComment": "Nuovo Commento",
            "send": "Invia",
            "sendFeedback": "Invia Feedback",
            "customization": "Personalizzazione",
            "forms": "Moduli",
            "description": "Descrizione",
            "categories": "Categorie",
            "category": "Categoria",
            "name": "Nome",
            "search": "Cerca",
            "guests": "Ospiti",
            "guest": "Ospite",
            "assign": "Assegna",
        }
    },
    en: {
        translation: {
            "user":"User",
            'loginHeader': 'Welcome',
            'loginDescription': 'Log in on the internal platform',
            'loginBtn': 'Log In',
            "recoveryHeader": "Password Recovery",
            "recoveryDescription": "Provide the email address where you want to receive the recovery instructions",
            "proceed": "Proceed",
            'demoLabel': 'Use',
            "emailAddress": "Email Address",
            'forgotPwd': 'Recover password',
            "requiredField": "Required field",
            "notValidEmail": "Not valid email",
            'logout': 'Logout',
            "profile": "Account",
            "accountGeneral": "Overview",
            "accountSecurity": "Security",
            "setAvatar": "Set avatar",
            "removeAvatar": "Remove avatar",
            "profileT": "Profile",
            "firstName": "First name",
            "lastName": "Last name",
            "save": "Save",

            "changePwd": "Change password",
            "oldPwd": "Old password",
            "password": "Password",
            "confirmPassword": "Confirm password",
            "pwdMismatch": "Password must be identical",
            "pwdUpdated": "Password updated",
            "minLen7": "Min len: 7 characters",

            "newUser": "New user",
            "user_breadcrumb": "New user",
            "usersT": "Users",
            "newUserTitle": "Login credentials",
            "newUserTitle2": "Overview",

            /*ROLES & PERMS*/
            "role":"Role",
            "roles":"Roles",
            "policy":"Policy",
            "groups":"Groups",
            "resource":"Resource",
            "subject":"Subject",
            "domain":"Domain",
            "newRole":"New Role",
            "roleInfo":"Role Info",
            "newPolicy":"New Policy",
            "newGroup":"New Group",
            "policySettings":"Policy Settings",
            "policySettings1":"Policy Settings",
            "roleCreated":"Role Created",
            "errorLoadingPolicy":"Error while loading policies",
            "errorLoadingGroups":"Error while loading groups",
            "policyCreated":"Policy created",
            "policyInfo":"Informazioni Policy",
            "groupInfo":"Group Info",
            "groupCreated":"Group created",
            "allow":"Allow",
            "deny":"Deny",

            /*SETTINGS */
            "theme": "Theme",
            "DARK": "Dark",
            "LIGHT": "Light",
            "NATURE": "Nature",
            "settings": "Settings",
            "saveSettings": "Save settings",
            "rtl": "Change text direction",
            "responsiveFont": "Responsive font sizes",
            "responsiveFontDescription": "Adjust font for small devices",
            "compact": "Compact",
            "compactDescription": "Fixed width on some screens",
            "roundBorders": "Rounded Corners",
            "roundBordersDescription": "Increase border radius",

            /* GENERAL */
            "back": "Back",
            "confirm": "Confirm",
            "actions": "Actions",
            "action": "Action",
            "by": "By",
            "at": "At",
            "open": "Open",
            "acknowledge": "Acknowledge",
            "closed": "Closed",
            "idle": "Idle",
            "details": "Details",
            "closeIt": "Close",
            "openIt": "Open",
            "delete": "Delete",
            "cancel": "Cancel",
            "warning": "Warning",
            "new": "New",
            "add": "Add",
            "logs": "Logs",
            "waiting": "Waiting",
            "notAssigned": "Not Assigned",

            /* DIALOG */
            "definitiveAction": "This is a definitive action, are you sure?",

            /* NOTIFICATIONS */
            "error": "Error",
            "success": "Success!",

            /* SIDEBAR */
            "users": "Users",
            "list": "List",
            "rolesAndPerms": "Roles and Permissions",
            "tables": "Tables",
            "general": "General",

            /* YEOMAN EN ANCHOR */
            "loginSSO": "Log In with Allianz SSO",
            /* DATE FILTER */
            "startDate": "Start Date",
            "endDate": "End Date",
            "today": "Today",
            "yesterday": "Yesterday",
            "tomorrow": "Tomorrow",
            "week": "Week",
            "month": "Mese",
            "lastMonth": "Last Month",
            "nextMonth": "Next Month",

            /* GUEST REPORT */
            "originalReport": "Original Report",
            "currentStatus": "Current Status",
            "lastFollowup": "Last Follow-Up",

            /* TABLE */
            "of": "of",
            "tableNoData": "No data was found",
            "select": "Select",
            "memoFilters": "Remember Filters",
            "resetFilters": "Reset Filters",
            "view": "View",
            "page": "Page",

            /* YEOMAN EN ANCHOR */
            "reports": "Reports",
            "report": "Report",
            "state": "State",
            "assigned": "Assigned to",
            "createdAt": "Created At",
            "createdBy": "Created By",
            "updatedAt": "Updated At",
            "updatedBy": "Updated By",
            "receivedAt": "Received At",
            "attachments": "Attachments",
            "newComment": "New Comment",
            "send": "Send",
            "sendFeedback": "Send Feedback",
            "customization": "Customization",
            "forms": "Forms",
            "description": "Description",
            "categories": "Categories",
            "category": "Category",
            "name": "Name",
            "search": "Search",
            "guests": "Guests",
            "guest": "Guest",
        }
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: window.localStorage.getItem('lang'),
        fallbackLng: 'it',
        interpolation: {
            escapeValue: false
        }
    });
