import type { FC, ReactNode } from 'react';
import React, { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Login from '../pages/authentication/Login';
import { useSelector } from "../store";
import SplashScreen from "./SplashScreen";
import { login, loginSSO, refreshToken, ssoValidateQuery } from "../pages/login/commands.login";

interface AuthGuardProps {
    children: ReactNode;
}

export function getSSOToken(name) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    }
    else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
            end = dc.length;
        }
    }
    // because unescape has been deprecated, replaced with decodeURI
    //return unescape(dc.substring(begin + prefix.length, end));
    return decodeURI(dc.substring(begin + prefix.length, end));
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
    const { children } = props;
    const auth = useSelector(state => state.auth);
    const location = useLocation();
    const navigate = useNavigate();
    const [requestedLocation, setRequestedLocation] = useState(null);

    if (auth.refreshingToken) {
        return <SplashScreen />;
    }

    const samlToken = new URLSearchParams(location.search);
    let code = samlToken.get('r')
    if (auth.logged && !auth.tokenRefreshed) {
        refreshToken(auth.accessToken);
        return <SplashScreen />;
    }

    if (!auth.logged) {
        if (location.pathname !== requestedLocation) {
            setRequestedLocation(location.pathname);
        }

        if (code != null) {
            loginSSO(code).then(res => {
                if (!res.logged) {
                    console.log(res)
                    // navigate("/?err=unauthorized", { replace: true })
                    return <Login error={true} />
                }
                console.log(res)
                return <>{children}</>;
            })
        }

        return <Login error={false} />;
    }

    // This is done so that in case the route changes by any chance through other
    // means between the moment of request and the render we navigate to the initially
    // requested route.
    if (requestedLocation && location.pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
    }

    return <>{children}</>;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
