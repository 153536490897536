import GraphqlClient from "../../client/graphql.client";

export const logAccessi = async () => {
  try {
    const res = await GraphqlClient.getLogsAccessi();
    return { data: res.getLogsAccessi }
  } catch (err) {
    return { error: true, message: err }
  }
};

export const logSeq = async () => {
  try {
    const res = await GraphqlClient.getLogsSequenze();
    return { data: res.getLogsSequenze }
  } catch (err) {
    return { error: true, message: err }
  }
};

export const sequenze = async () => {
  try {
    const res = await GraphqlClient.getSequenze();
    return { data: res.getSequenze }
  } catch (err) {
    return { error: true, message: err }
  }
};
