import type { GraphQLClient } from 'graphql-request';
import type * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Json: any;
  Time: any;
  UnixDate: any;
  Upload: any;
};





export type AggiornaRichiesta = {
  cid: Scalars['String'];
  giorni?: Maybe<Scalars['Int']>;
  audio?: Maybe<Scalars['String']>;
  certificato: Scalars['Boolean'];
};

export type AudioDownload = {
  __typename?: 'AudioDownload';
  id?: Maybe<Scalars['ID']>;
  base64?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
};

export type Dipendente = {
  __typename?: 'Dipendente';
  createdAt?: Maybe<Scalars['Int']>;
  nome?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  cid?: Maybe<Scalars['String']>;
  dataNascita?: Maybe<Scalars['Int']>;
  notificationCid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Int']>;
  richieste?: Maybe<Array<Maybe<Richiesta>>>;
};

export type FilterInput = {
  column: Scalars['String'];
  value?: Maybe<Array<Scalars['String']>>;
  type: Scalars['String'];
};

export type Group = {
  __typename?: 'Group';
  subject: Scalars['String'];
  role: Scalars['String'];
  domain: Scalars['String'];
};


export type LogAccessi = {
  __typename?: 'LogAccessi';
  cid: Scalars['String'];
  createdAt: Scalars['Int'];
  success: Scalars['Boolean'];
  pending: Scalars['Boolean'];
};

export type LogSequenze = {
  __typename?: 'LogSequenze';
  content: Scalars['String'];
  createdAt: Scalars['Int'];
};

export type LoginToken = {
  __typename?: 'LoginToken';
  code: Scalars['String'];
  token: Scalars['String'];
  used: Scalars['Boolean'];
  expireAt: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  pingMutation: Scalars['String'];
  AddPolicy?: Maybe<Policy>;
  AddGroup?: Maybe<Group>;
  creaDipendente?: Maybe<Dipendente>;
  rimuoviDipendente?: Maybe<Dipendente>;
  importaDipendenti?: Maybe<Array<Maybe<Dipendente>>>;
  creaRichiesta?: Maybe<Richiesta>;
  rimuoviRichiesta?: Maybe<Richiesta>;
  chiudiRichiesteScadute?: Maybe<Array<Maybe<Richiesta>>>;
  aggiornaRichiesta?: Maybe<Richiesta>;
  createLoginToken?: Maybe<LoginToken>;
  updateLoginToken?: Maybe<LoginToken>;
  createUser: TokenDetails;
  updateUser: User;
  updateSelfPassword: User;
  updateUserPassword: User;
  recoverPassword: Scalars['Boolean'];
  resetPassword: TokenDetails;
  impersonates: TokenDetails;
  createRole?: Maybe<Role>;
};


export type MutationAddPolicyArgs = {
  subject: Scalars['String'];
  object: Scalars['String'];
  action: Scalars['String'];
};


export type MutationAddGroupArgs = {
  subject: Scalars['String'];
  role: Scalars['String'];
};


export type MutationCreaDipendenteArgs = {
  input: NuovoDipendente;
};


export type MutationRimuoviDipendenteArgs = {
  cid: Scalars['String'];
};


export type MutationImportaDipendentiArgs = {
  input: Scalars['String'];
};


export type MutationCreaRichiestaArgs = {
  input: NuovaRichiesta;
};


export type MutationRimuoviRichiestaArgs = {
  id: Scalars['String'];
};


export type MutationAggiornaRichiestaArgs = {
  input: AggiornaRichiesta;
};


export type MutationCreateLoginTokenArgs = {
  token: Scalars['String'];
};


export type MutationUpdateLoginTokenArgs = {
  code: Scalars['String'];
};


export type MutationCreateUserArgs = {
  user?: Maybe<UserInputWithPassword>;
};


export type MutationUpdateUserArgs = {
  email: Scalars['String'];
  user?: Maybe<UpdateUserInput>;
};


export type MutationUpdateSelfPasswordArgs = {
  email: Scalars['String'];
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationUpdateUserPasswordArgs = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationRecoverPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
  code: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationImpersonatesArgs = {
  email: Scalars['String'];
};


export type MutationCreateRoleArgs = {
  name: Scalars['String'];
  description: Scalars['String'];
};

export type NuovaRichiesta = {
  cid: Scalars['String'];
  inizio: Scalars['Int'];
  giorni: Scalars['Int'];
  certificato: Scalars['Boolean'];
  audio?: Maybe<Scalars['String']>;
};

export type NuovoDipendente = {
  nome?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  cid?: Maybe<Scalars['String']>;
  notificationCid?: Maybe<Scalars['String']>;
  dataNascita?: Maybe<Scalars['Int']>;
};

export type Policy = {
  __typename?: 'Policy';
  subject: Scalars['String'];
  domain: Scalars['String'];
  object: Scalars['String'];
  action: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  ping: Scalars['String'];
  getPolicies?: Maybe<Array<Maybe<Policy>>>;
  getGroups?: Maybe<Array<Maybe<Group>>>;
  dipendenti?: Maybe<Array<Maybe<Dipendente>>>;
  dipendente?: Maybe<Dipendente>;
  dipendentiBatch?: Maybe<Array<Maybe<Dipendente>>>;
  ottieniDestinatari: Scalars['String'];
  getLogsAccessi?: Maybe<Array<Maybe<LogAccessi>>>;
  getLogsSequenze?: Maybe<Array<Maybe<LogSequenze>>>;
  getSequenze?: Maybe<Array<Maybe<Sequenze>>>;
  richieste?: Maybe<Array<Maybe<Richiesta>>>;
  richiesta?: Maybe<Richiesta>;
  downloadAudio?: Maybe<AudioDownload>;
  richiesteOdierne: Scalars['Int'];
  richiesteSettimanali: Scalars['Int'];
  richiesteAperte: Scalars['Int'];
  richiesteMensili?: Maybe<Array<Maybe<RichiesteMensili>>>;
  users?: Maybe<Array<Maybe<User>>>;
  user: User;
  loggedUser: User;
  validateSSOToken: TokenDetails;
  basicLogin: TokenDetails;
  getNewAccessToken: TokenDetails;
  roles?: Maybe<Array<Maybe<Role>>>;
};


export type QueryDipendenteArgs = {
  cid: Scalars['String'];
};


export type QueryDipendentiBatchArgs = {
  cid?: Maybe<Array<Scalars['String']>>;
};


export type QueryOttieniDestinatariArgs = {
  cid: Scalars['String'];
};


export type QueryRichiestaArgs = {
  id: Scalars['String'];
};


export type QueryDownloadAudioArgs = {
  id: Scalars['String'];
};


export type QueryUserArgs = {
  email: Scalars['String'];
};


export type QueryValidateSsoTokenArgs = {
  ssoToken: Scalars['String'];
};


export type QueryBasicLoginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};


export type QueryGetNewAccessTokenArgs = {
  refreshToken: Scalars['String'];
};

export enum Resources {
  Users = 'USERS',
  Roles = 'ROLES',
  Groups = 'GROUPS',
  Policies = 'POLICIES'
}

export type ResponseDelete = {
  __typename?: 'ResponseDelete';
  success: Scalars['Boolean'];
  message: Scalars['String'];
  rowAffected: Scalars['Int'];
};

export type Richiesta = {
  __typename?: 'Richiesta';
  id: Scalars['String'];
  numero: Scalars['Int'];
  createdAt: Scalars['Int'];
  inizio: Scalars['Int'];
  fine?: Maybe<Scalars['Int']>;
  origine: Scalars['Int'];
  proroghe?: Maybe<Array<Maybe<Scalars['String']>>>;
  stato?: Maybe<Scalars['String']>;
  idAudio?: Maybe<Scalars['String']>;
  certificato: Scalars['Boolean'];
  dipendente?: Maybe<Dipendente>;
};

export type RichiesteMensili = {
  __typename?: 'RichiesteMensili';
  mese?: Maybe<Scalars['Int']>;
  richieste?: Maybe<Scalars['Int']>;
};

export type Role = {
  __typename?: 'Role';
  name: Scalars['String'];
  description: Scalars['String'];
};

export type Sequenze = {
  __typename?: 'Sequenze';
  content: Scalars['String'];
  createdAt: Scalars['Int'];
  sent: Scalars['Boolean'];
  sentAt?: Maybe<Scalars['Int']>;
  richiesta?: Maybe<Richiesta>;
};

export type TableInput = {
  records: Scalars['Int'];
  page: Scalars['Int'];
  orderBy: Scalars['String'];
  selectOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Array<Maybe<FilterInput>>>;
  sumColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
  avgColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TableOperation = {
  __typename?: 'TableOperation';
  id: Scalars['String'];
  result: Scalars['String'];
  type: Scalars['String'];
};

export type TableSelectOptions = {
  __typename?: 'TableSelectOptions';
  column: Scalars['String'];
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type TokenDetails = {
  __typename?: 'TokenDetails';
  accessToken?: Maybe<Scalars['String']>;
  refreshToken: Scalars['String'];
  accessUuid: Scalars['String'];
  refreshUuid: Scalars['String'];
  atExpires: Scalars['Int'];
  rtExpires: Scalars['Int'];
  user: User;
  permissions: Scalars['String'];
  isSSO: Scalars['Boolean'];
};


export type UpdateUserInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};


export type User = {
  __typename?: 'User';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  avatar: Scalars['String'];
  createdAt?: Maybe<Scalars['Time']>;
};

export type UserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  confirmPassword: Scalars['String'];
  salt: Scalars['String'];
  email: Scalars['String'];
  avatar: Scalars['String'];
};

export type UserInputWithPassword = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  confirmPassword: Scalars['String'];
  email: Scalars['String'];
};

export type AddGroupMutationVariables = Exact<{
  subject: Scalars['String'];
  role: Scalars['String'];
}>;


export type AddGroupMutation = (
  { __typename?: 'Mutation' }
  & { AddGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'subject' | 'role' | 'domain'>
  )> }
);

export type AddPolicyMutationVariables = Exact<{
  subject: Scalars['String'];
  object: Scalars['String'];
  action: Scalars['String'];
}>;


export type AddPolicyMutation = (
  { __typename?: 'Mutation' }
  & { AddPolicy?: Maybe<(
    { __typename?: 'Policy' }
    & Pick<Policy, 'subject' | 'domain' | 'object' | 'action'>
  )> }
);

export type AggiornaRichiestaMutationVariables = Exact<{
  input: AggiornaRichiesta;
}>;


export type AggiornaRichiestaMutation = (
  { __typename?: 'Mutation' }
  & { aggiornaRichiesta?: Maybe<(
    { __typename?: 'Richiesta' }
    & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
    & { dipendente?: Maybe<(
      { __typename?: 'Dipendente' }
      & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
      & { richieste?: Maybe<Array<Maybe<(
        { __typename?: 'Richiesta' }
        & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
        & { dipendente?: Maybe<(
          { __typename?: 'Dipendente' }
          & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
        )> }
      )>>> }
    )> }
  )> }
);

export type ChiudiRichiesteScaduteMutationVariables = Exact<{ [key: string]: never; }>;


export type ChiudiRichiesteScaduteMutation = (
  { __typename?: 'Mutation' }
  & { chiudiRichiesteScadute?: Maybe<Array<Maybe<(
    { __typename?: 'Richiesta' }
    & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
    & { dipendente?: Maybe<(
      { __typename?: 'Dipendente' }
      & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
      & { richieste?: Maybe<Array<Maybe<(
        { __typename?: 'Richiesta' }
        & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
        & { dipendente?: Maybe<(
          { __typename?: 'Dipendente' }
          & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
        )> }
      )>>> }
    )> }
  )>>> }
);

export type CreaDipendenteMutationVariables = Exact<{
  input: NuovoDipendente;
}>;


export type CreaDipendenteMutation = (
  { __typename?: 'Mutation' }
  & { creaDipendente?: Maybe<(
    { __typename?: 'Dipendente' }
    & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
    & { richieste?: Maybe<Array<Maybe<(
      { __typename?: 'Richiesta' }
      & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
      & { dipendente?: Maybe<(
        { __typename?: 'Dipendente' }
        & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
        & { richieste?: Maybe<Array<Maybe<(
          { __typename?: 'Richiesta' }
          & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type CreaRichiestaMutationVariables = Exact<{
  input: NuovaRichiesta;
}>;


export type CreaRichiestaMutation = (
  { __typename?: 'Mutation' }
  & { creaRichiesta?: Maybe<(
    { __typename?: 'Richiesta' }
    & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
    & { dipendente?: Maybe<(
      { __typename?: 'Dipendente' }
      & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
      & { richieste?: Maybe<Array<Maybe<(
        { __typename?: 'Richiesta' }
        & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
        & { dipendente?: Maybe<(
          { __typename?: 'Dipendente' }
          & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
        )> }
      )>>> }
    )> }
  )> }
);

export type CreateLoginTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type CreateLoginTokenMutation = (
  { __typename?: 'Mutation' }
  & { createLoginToken?: Maybe<(
    { __typename?: 'LoginToken' }
    & Pick<LoginToken, 'code' | 'token' | 'used' | 'expireAt'>
  )> }
);

export type CreateRoleMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
}>;


export type CreateRoleMutation = (
  { __typename?: 'Mutation' }
  & { createRole?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'name' | 'description'>
  )> }
);

export type CreateUserMutationVariables = Exact<{
  user?: Maybe<UserInputWithPassword>;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions' | 'isSSO'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
    ) }
  ) }
);

export type ImpersonatesMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ImpersonatesMutation = (
  { __typename?: 'Mutation' }
  & { impersonates: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions' | 'isSSO'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
    ) }
  ) }
);

export type ImportaDipendentiMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type ImportaDipendentiMutation = (
  { __typename?: 'Mutation' }
  & { importaDipendenti?: Maybe<Array<Maybe<(
    { __typename?: 'Dipendente' }
    & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
    & { richieste?: Maybe<Array<Maybe<(
      { __typename?: 'Richiesta' }
      & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
      & { dipendente?: Maybe<(
        { __typename?: 'Dipendente' }
        & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
        & { richieste?: Maybe<Array<Maybe<(
          { __typename?: 'Richiesta' }
          & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
        )>>> }
      )> }
    )>>> }
  )>>> }
);

export type PingMutationMutationVariables = Exact<{ [key: string]: never; }>;


export type PingMutationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'pingMutation'>
);

export type RecoverPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RecoverPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'recoverPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  code: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions' | 'isSSO'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
    ) }
  ) }
);

export type RimuoviDipendenteMutationVariables = Exact<{
  cid: Scalars['String'];
}>;


export type RimuoviDipendenteMutation = (
  { __typename?: 'Mutation' }
  & { rimuoviDipendente?: Maybe<(
    { __typename?: 'Dipendente' }
    & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
    & { richieste?: Maybe<Array<Maybe<(
      { __typename?: 'Richiesta' }
      & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
      & { dipendente?: Maybe<(
        { __typename?: 'Dipendente' }
        & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
        & { richieste?: Maybe<Array<Maybe<(
          { __typename?: 'Richiesta' }
          & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type RimuoviRichiestaMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RimuoviRichiestaMutation = (
  { __typename?: 'Mutation' }
  & { rimuoviRichiesta?: Maybe<(
    { __typename?: 'Richiesta' }
    & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
    & { dipendente?: Maybe<(
      { __typename?: 'Dipendente' }
      & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
      & { richieste?: Maybe<Array<Maybe<(
        { __typename?: 'Richiesta' }
        & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
        & { dipendente?: Maybe<(
          { __typename?: 'Dipendente' }
          & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
        )> }
      )>>> }
    )> }
  )> }
);

export type UpdateLoginTokenMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type UpdateLoginTokenMutation = (
  { __typename?: 'Mutation' }
  & { updateLoginToken?: Maybe<(
    { __typename?: 'LoginToken' }
    & Pick<LoginToken, 'code' | 'token' | 'used' | 'expireAt'>
  )> }
);

export type UpdateSelfPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type UpdateSelfPasswordMutation = (
  { __typename?: 'Mutation' }
  & { updateSelfPassword: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  email: Scalars['String'];
  user?: Maybe<UpdateUserInput>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
  ) }
);

export type UpdateUserPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type UpdateUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & { updateUserPassword: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
  ) }
);

export type BasicLoginQueryVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type BasicLoginQuery = (
  { __typename?: 'Query' }
  & { basicLogin: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions' | 'isSSO'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
    ) }
  ) }
);

export type DipendenteQueryVariables = Exact<{
  cid: Scalars['String'];
}>;


export type DipendenteQuery = (
  { __typename?: 'Query' }
  & { dipendente?: Maybe<(
    { __typename?: 'Dipendente' }
    & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
    & { richieste?: Maybe<Array<Maybe<(
      { __typename?: 'Richiesta' }
      & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
      & { dipendente?: Maybe<(
        { __typename?: 'Dipendente' }
        & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
      )> }
    )>>> }
  )> }
);

export type DipendentiQueryVariables = Exact<{ [key: string]: never; }>;


export type DipendentiQuery = (
  { __typename?: 'Query' }
  & { dipendenti?: Maybe<Array<Maybe<(
    { __typename?: 'Dipendente' }
    & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
    & { richieste?: Maybe<Array<Maybe<(
      { __typename?: 'Richiesta' }
      & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
      & { dipendente?: Maybe<(
        { __typename?: 'Dipendente' }
        & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
        & { richieste?: Maybe<Array<Maybe<(
          { __typename?: 'Richiesta' }
          & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
        )>>> }
      )> }
    )>>> }
  )>>> }
);

export type DipendentiBatchQueryVariables = Exact<{
  cid?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type DipendentiBatchQuery = (
  { __typename?: 'Query' }
  & { dipendentiBatch?: Maybe<Array<Maybe<(
    { __typename?: 'Dipendente' }
    & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
    & { richieste?: Maybe<Array<Maybe<(
      { __typename?: 'Richiesta' }
      & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
      & { dipendente?: Maybe<(
        { __typename?: 'Dipendente' }
        & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
        & { richieste?: Maybe<Array<Maybe<(
          { __typename?: 'Richiesta' }
          & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
        )>>> }
      )> }
    )>>> }
  )>>> }
);

export type DownloadAudioQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type DownloadAudioQuery = (
  { __typename?: 'Query' }
  & { downloadAudio?: Maybe<(
    { __typename?: 'AudioDownload' }
    & Pick<AudioDownload, 'id' | 'base64' | 'filename' | 'extension'>
  )> }
);

export type GetGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupsQuery = (
  { __typename?: 'Query' }
  & { getGroups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'subject' | 'role' | 'domain'>
  )>>> }
);

export type GetLogsAccessiQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLogsAccessiQuery = (
  { __typename?: 'Query' }
  & { getLogsAccessi?: Maybe<Array<Maybe<(
    { __typename?: 'LogAccessi' }
    & Pick<LogAccessi, 'cid' | 'createdAt' | 'success' | 'pending'>
  )>>> }
);

export type GetLogsSequenzeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLogsSequenzeQuery = (
  { __typename?: 'Query' }
  & { getLogsSequenze?: Maybe<Array<Maybe<(
    { __typename?: 'LogSequenze' }
    & Pick<LogSequenze, 'content' | 'createdAt'>
  )>>> }
);

export type GetNewAccessTokenQueryVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type GetNewAccessTokenQuery = (
  { __typename?: 'Query' }
  & { getNewAccessToken: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions' | 'isSSO'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
    ) }
  ) }
);

export type GetPoliciesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPoliciesQuery = (
  { __typename?: 'Query' }
  & { getPolicies?: Maybe<Array<Maybe<(
    { __typename?: 'Policy' }
    & Pick<Policy, 'subject' | 'domain' | 'object' | 'action'>
  )>>> }
);

export type GetSequenzeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSequenzeQuery = (
  { __typename?: 'Query' }
  & { getSequenze?: Maybe<Array<Maybe<(
    { __typename?: 'Sequenze' }
    & Pick<Sequenze, 'content' | 'createdAt' | 'sent' | 'sentAt'>
    & { richiesta?: Maybe<(
      { __typename?: 'Richiesta' }
      & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
      & { dipendente?: Maybe<(
        { __typename?: 'Dipendente' }
        & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
        & { richieste?: Maybe<Array<Maybe<(
          { __typename?: 'Richiesta' }
          & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
          & { dipendente?: Maybe<(
            { __typename?: 'Dipendente' }
            & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
          )> }
        )>>> }
      )> }
    )> }
  )>>> }
);

export type LoggedUserQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedUserQuery = (
  { __typename?: 'Query' }
  & { loggedUser: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
  ) }
);

export type OttieniDestinatariQueryVariables = Exact<{
  cid: Scalars['String'];
}>;


export type OttieniDestinatariQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ottieniDestinatari'>
);

export type PingQueryVariables = Exact<{ [key: string]: never; }>;


export type PingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ping'>
);

export type RichiestaQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type RichiestaQuery = (
  { __typename?: 'Query' }
  & { richiesta?: Maybe<(
    { __typename?: 'Richiesta' }
    & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
    & { dipendente?: Maybe<(
      { __typename?: 'Dipendente' }
      & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
      & { richieste?: Maybe<Array<Maybe<(
        { __typename?: 'Richiesta' }
        & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
        & { dipendente?: Maybe<(
          { __typename?: 'Dipendente' }
          & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
        )> }
      )>>> }
    )> }
  )> }
);

export type RichiesteQueryVariables = Exact<{ [key: string]: never; }>;


export type RichiesteQuery = (
  { __typename?: 'Query' }
  & { richieste?: Maybe<Array<Maybe<(
    { __typename?: 'Richiesta' }
    & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
    & { dipendente?: Maybe<(
      { __typename?: 'Dipendente' }
      & Pick<Dipendente, 'createdAt' | 'nome' | 'cognome' | 'email' | 'cid' | 'dataNascita' | 'notificationCid' | 'updatedAt'>
      & { richieste?: Maybe<Array<Maybe<(
        { __typename?: 'Richiesta' }
        & Pick<Richiesta, 'id' | 'numero' | 'createdAt' | 'inizio' | 'fine' | 'origine' | 'proroghe' | 'stato' | 'idAudio' | 'certificato'>
      )>>> }
    )> }
  )>>> }
);

export type RichiesteAperteQueryVariables = Exact<{ [key: string]: never; }>;


export type RichiesteAperteQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'richiesteAperte'>
);

export type RichiesteMensiliQueryVariables = Exact<{ [key: string]: never; }>;


export type RichiesteMensiliQuery = (
  { __typename?: 'Query' }
  & { richiesteMensili?: Maybe<Array<Maybe<(
    { __typename?: 'RichiesteMensili' }
    & Pick<RichiesteMensili, 'mese' | 'richieste'>
  )>>> }
);

export type RichiesteOdierneQueryVariables = Exact<{ [key: string]: never; }>;


export type RichiesteOdierneQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'richiesteOdierne'>
);

export type RichiesteSettimanaliQueryVariables = Exact<{ [key: string]: never; }>;


export type RichiesteSettimanaliQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'richiesteSettimanali'>
);

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = (
  { __typename?: 'Query' }
  & { roles?: Maybe<Array<Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'name' | 'description'>
  )>>> }
);

export type UserQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
  ) }
);

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
  )>>> }
);

export type ValidateSsoTokenQueryVariables = Exact<{
  ssoToken: Scalars['String'];
}>;


export type ValidateSsoTokenQuery = (
  { __typename?: 'Query' }
  & { validateSSOToken: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions' | 'isSSO'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
    ) }
  ) }
);


export const AddGroupDocument = gql`
    mutation AddGroup($subject: String!, $role: String!) {
  AddGroup(subject: $subject, role: $role) {
    subject
    role
    domain
  }
}
    `;
export const AddPolicyDocument = gql`
    mutation AddPolicy($subject: String!, $object: String!, $action: String!) {
  AddPolicy(subject: $subject, object: $object, action: $action) {
    subject
    domain
    object
    action
  }
}
    `;
export const AggiornaRichiestaDocument = gql`
    mutation aggiornaRichiesta($input: AggiornaRichiesta!) {
  aggiornaRichiesta(input: $input) {
    id
    numero
    createdAt
    inizio
    fine
    origine
    proroghe
    stato
    idAudio
    certificato
    dipendente {
      createdAt
      nome
      cognome
      email
      cid
      dataNascita
      notificationCid
      updatedAt
      richieste {
        id
        numero
        createdAt
        inizio
        fine
        origine
        proroghe
        stato
        idAudio
        certificato
        dipendente {
          createdAt
          nome
          cognome
          email
          cid
          dataNascita
          notificationCid
          updatedAt
        }
      }
    }
  }
}
    `;
export const ChiudiRichiesteScaduteDocument = gql`
    mutation chiudiRichiesteScadute {
  chiudiRichiesteScadute {
    id
    numero
    createdAt
    inizio
    fine
    origine
    proroghe
    stato
    idAudio
    certificato
    dipendente {
      createdAt
      nome
      cognome
      email
      cid
      dataNascita
      notificationCid
      updatedAt
      richieste {
        id
        numero
        createdAt
        inizio
        fine
        origine
        proroghe
        stato
        idAudio
        certificato
        dipendente {
          createdAt
          nome
          cognome
          email
          cid
          dataNascita
          notificationCid
          updatedAt
        }
      }
    }
  }
}
    `;
export const CreaDipendenteDocument = gql`
    mutation creaDipendente($input: NuovoDipendente!) {
  creaDipendente(input: $input) {
    createdAt
    nome
    cognome
    email
    cid
    dataNascita
    notificationCid
    updatedAt
    richieste {
      id
      numero
      createdAt
      inizio
      fine
      origine
      proroghe
      stato
      idAudio
      certificato
      dipendente {
        createdAt
        nome
        cognome
        email
        cid
        dataNascita
        notificationCid
        updatedAt
        richieste {
          id
          numero
          createdAt
          inizio
          fine
          origine
          proroghe
          stato
          idAudio
          certificato
        }
      }
    }
  }
}
    `;
export const CreaRichiestaDocument = gql`
    mutation creaRichiesta($input: NuovaRichiesta!) {
  creaRichiesta(input: $input) {
    id
    numero
    createdAt
    inizio
    fine
    origine
    proroghe
    stato
    idAudio
    certificato
    dipendente {
      createdAt
      nome
      cognome
      email
      cid
      dataNascita
      notificationCid
      updatedAt
      richieste {
        id
        numero
        createdAt
        inizio
        fine
        origine
        proroghe
        stato
        idAudio
        certificato
        dipendente {
          createdAt
          nome
          cognome
          email
          cid
          dataNascita
          notificationCid
          updatedAt
        }
      }
    }
  }
}
    `;
export const CreateLoginTokenDocument = gql`
    mutation createLoginToken($token: String!) {
  createLoginToken(token: $token) {
    code
    token
    used
    expireAt
  }
}
    `;
export const CreateRoleDocument = gql`
    mutation createRole($name: String!, $description: String!) {
  createRole(name: $name, description: $description) {
    name
    description
  }
}
    `;
export const CreateUserDocument = gql`
    mutation createUser($user: UserInputWithPassword) {
  createUser(user: $user) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
    }
    permissions
    isSSO
  }
}
    `;
export const ImpersonatesDocument = gql`
    mutation impersonates($email: String!) {
  impersonates(email: $email) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
    }
    permissions
    isSSO
  }
}
    `;
export const ImportaDipendentiDocument = gql`
    mutation importaDipendenti($input: String!) {
  importaDipendenti(input: $input) {
    createdAt
    nome
    cognome
    email
    cid
    dataNascita
    notificationCid
    updatedAt
    richieste {
      id
      numero
      createdAt
      inizio
      fine
      origine
      proroghe
      stato
      idAudio
      certificato
      dipendente {
        createdAt
        nome
        cognome
        email
        cid
        dataNascita
        notificationCid
        updatedAt
        richieste {
          id
          numero
          createdAt
          inizio
          fine
          origine
          proroghe
          stato
          idAudio
          certificato
        }
      }
    }
  }
}
    `;
export const PingMutationDocument = gql`
    mutation pingMutation {
  pingMutation
}
    `;
export const RecoverPasswordDocument = gql`
    mutation recoverPassword($email: String!) {
  recoverPassword(email: $email)
}
    `;
export const ResetPasswordDocument = gql`
    mutation resetPassword($email: String!, $code: String!, $newPassword: String!) {
  resetPassword(email: $email, code: $code, newPassword: $newPassword) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
    }
    permissions
    isSSO
  }
}
    `;
export const RimuoviDipendenteDocument = gql`
    mutation rimuoviDipendente($cid: String!) {
  rimuoviDipendente(cid: $cid) {
    createdAt
    nome
    cognome
    email
    cid
    dataNascita
    notificationCid
    updatedAt
    richieste {
      id
      numero
      createdAt
      inizio
      fine
      origine
      proroghe
      stato
      idAudio
      certificato
      dipendente {
        createdAt
        nome
        cognome
        email
        cid
        dataNascita
        notificationCid
        updatedAt
        richieste {
          id
          numero
          createdAt
          inizio
          fine
          origine
          proroghe
          stato
          idAudio
          certificato
        }
      }
    }
  }
}
    `;
export const RimuoviRichiestaDocument = gql`
    mutation rimuoviRichiesta($id: String!) {
  rimuoviRichiesta(id: $id) {
    id
    numero
    createdAt
    inizio
    fine
    origine
    proroghe
    stato
    idAudio
    certificato
    dipendente {
      createdAt
      nome
      cognome
      email
      cid
      dataNascita
      notificationCid
      updatedAt
      richieste {
        id
        numero
        createdAt
        inizio
        fine
        origine
        proroghe
        stato
        idAudio
        certificato
        dipendente {
          createdAt
          nome
          cognome
          email
          cid
          dataNascita
          notificationCid
          updatedAt
        }
      }
    }
  }
}
    `;
export const UpdateLoginTokenDocument = gql`
    mutation updateLoginToken($code: String!) {
  updateLoginToken(code: $code) {
    code
    token
    used
    expireAt
  }
}
    `;
export const UpdateSelfPasswordDocument = gql`
    mutation updateSelfPassword($email: String!, $oldPassword: String!, $newPassword: String!) {
  updateSelfPassword(
    email: $email
    oldPassword: $oldPassword
    newPassword: $newPassword
  ) {
    firstName
    lastName
    email
    avatar
    createdAt
  }
}
    `;
export const UpdateUserDocument = gql`
    mutation updateUser($email: String!, $user: UpdateUserInput) {
  updateUser(email: $email, user: $user) {
    firstName
    lastName
    email
    avatar
    createdAt
  }
}
    `;
export const UpdateUserPasswordDocument = gql`
    mutation updateUserPassword($email: String!, $newPassword: String!) {
  updateUserPassword(email: $email, newPassword: $newPassword) {
    firstName
    lastName
    email
    avatar
    createdAt
  }
}
    `;
export const BasicLoginDocument = gql`
    query basicLogin($username: String!, $password: String!) {
  basicLogin(username: $username, password: $password) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
    }
    permissions
    isSSO
  }
}
    `;
export const DipendenteDocument = gql`
    query dipendente($cid: String!) {
  dipendente(cid: $cid) {
    createdAt
    nome
    cognome
    email
    cid
    dataNascita
    notificationCid
    updatedAt
    richieste {
      id
      numero
      createdAt
      inizio
      fine
      origine
      proroghe
      stato
      idAudio
      certificato
      dipendente {
        createdAt
        nome
        cognome
        email
        cid
        dataNascita
        notificationCid
        updatedAt
      }
    }
  }
}
    `;
export const DipendentiDocument = gql`
    query dipendenti {
  dipendenti {
    createdAt
    nome
    cognome
    email
    cid
    dataNascita
    notificationCid
    updatedAt
    richieste {
      id
      numero
      createdAt
      inizio
      fine
      origine
      proroghe
      stato
      idAudio
      certificato
      dipendente {
        createdAt
        nome
        cognome
        email
        cid
        dataNascita
        notificationCid
        updatedAt
        richieste {
          id
          numero
          createdAt
          inizio
          fine
          origine
          proroghe
          stato
          idAudio
          certificato
        }
      }
    }
  }
}
    `;
export const DipendentiBatchDocument = gql`
    query dipendentiBatch($cid: [String!]) {
  dipendentiBatch(cid: $cid) {
    createdAt
    nome
    cognome
    email
    cid
    dataNascita
    notificationCid
    updatedAt
    richieste {
      id
      numero
      createdAt
      inizio
      fine
      origine
      proroghe
      stato
      idAudio
      certificato
      dipendente {
        createdAt
        nome
        cognome
        email
        cid
        dataNascita
        notificationCid
        updatedAt
        richieste {
          id
          numero
          createdAt
          inizio
          fine
          origine
          proroghe
          stato
          idAudio
          certificato
        }
      }
    }
  }
}
    `;
export const DownloadAudioDocument = gql`
    query downloadAudio($id: String!) {
  downloadAudio(id: $id) {
    id
    base64
    filename
    extension
  }
}
    `;
export const GetGroupsDocument = gql`
    query getGroups {
  getGroups {
    subject
    role
    domain
  }
}
    `;
export const GetLogsAccessiDocument = gql`
    query getLogsAccessi {
  getLogsAccessi {
    cid
    createdAt
    success
    pending
  }
}
    `;
export const GetLogsSequenzeDocument = gql`
    query getLogsSequenze {
  getLogsSequenze {
    content
    createdAt
  }
}
    `;
export const GetNewAccessTokenDocument = gql`
    query getNewAccessToken($refreshToken: String!) {
  getNewAccessToken(refreshToken: $refreshToken) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
    }
    permissions
    isSSO
  }
}
    `;
export const GetPoliciesDocument = gql`
    query getPolicies {
  getPolicies {
    subject
    domain
    object
    action
  }
}
    `;
export const GetSequenzeDocument = gql`
    query getSequenze {
  getSequenze {
    content
    createdAt
    sent
    sentAt
    richiesta {
      id
      numero
      createdAt
      inizio
      fine
      origine
      proroghe
      stato
      idAudio
      certificato
      dipendente {
        createdAt
        nome
        cognome
        email
        cid
        dataNascita
        notificationCid
        updatedAt
        richieste {
          id
          numero
          createdAt
          inizio
          fine
          origine
          proroghe
          stato
          idAudio
          certificato
          dipendente {
            createdAt
            nome
            cognome
            email
            cid
            dataNascita
            notificationCid
            updatedAt
          }
        }
      }
    }
  }
}
    `;
export const LoggedUserDocument = gql`
    query loggedUser {
  loggedUser {
    firstName
    lastName
    email
    avatar
    createdAt
  }
}
    `;
export const OttieniDestinatariDocument = gql`
    query ottieniDestinatari($cid: String!) {
  ottieniDestinatari(cid: $cid)
}
    `;
export const PingDocument = gql`
    query ping {
  ping
}
    `;
export const RichiestaDocument = gql`
    query richiesta($id: String!) {
  richiesta(id: $id) {
    id
    numero
    createdAt
    inizio
    fine
    origine
    proroghe
    stato
    idAudio
    certificato
    dipendente {
      createdAt
      nome
      cognome
      email
      cid
      dataNascita
      notificationCid
      updatedAt
      richieste {
        id
        numero
        createdAt
        inizio
        fine
        origine
        proroghe
        stato
        idAudio
        certificato
        dipendente {
          createdAt
          nome
          cognome
          email
          cid
          dataNascita
          notificationCid
          updatedAt
        }
      }
    }
  }
}
    `;
export const RichiesteDocument = gql`
    query richieste {
  richieste {
    id
    numero
    createdAt
    inizio
    fine
    origine
    proroghe
    stato
    idAudio
    certificato
    dipendente {
      createdAt
      nome
      cognome
      email
      cid
      dataNascita
      notificationCid
      updatedAt
      richieste {
        id
        numero
        createdAt
        inizio
        fine
        origine
        proroghe
        stato
        idAudio
        certificato
      }
    }
  }
}
    `;
export const RichiesteAperteDocument = gql`
    query richiesteAperte {
  richiesteAperte
}
    `;
export const RichiesteMensiliDocument = gql`
    query richiesteMensili {
  richiesteMensili {
    mese
    richieste
  }
}
    `;
export const RichiesteOdierneDocument = gql`
    query richiesteOdierne {
  richiesteOdierne
}
    `;
export const RichiesteSettimanaliDocument = gql`
    query richiesteSettimanali {
  richiesteSettimanali
}
    `;
export const RolesDocument = gql`
    query roles {
  roles {
    name
    description
  }
}
    `;
export const UserDocument = gql`
    query user($email: String!) {
  user(email: $email) {
    firstName
    lastName
    email
    avatar
    createdAt
  }
}
    `;
export const UsersDocument = gql`
    query users {
  users {
    firstName
    lastName
    email
    avatar
    createdAt
  }
}
    `;
export const ValidateSsoTokenDocument = gql`
    query validateSSOToken($ssoToken: String!) {
  validateSSOToken(ssoToken: $ssoToken) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
    }
    permissions
    isSSO
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    AddGroup(variables: AddGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddGroupMutation> {
      return withWrapper(() => client.request<AddGroupMutation>(AddGroupDocument, variables, requestHeaders));
    },
    AddPolicy(variables: AddPolicyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddPolicyMutation> {
      return withWrapper(() => client.request<AddPolicyMutation>(AddPolicyDocument, variables, requestHeaders));
    },
    aggiornaRichiesta(variables: AggiornaRichiestaMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AggiornaRichiestaMutation> {
      return withWrapper(() => client.request<AggiornaRichiestaMutation>(AggiornaRichiestaDocument, variables, requestHeaders));
    },
    chiudiRichiesteScadute(variables?: ChiudiRichiesteScaduteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ChiudiRichiesteScaduteMutation> {
      return withWrapper(() => client.request<ChiudiRichiesteScaduteMutation>(ChiudiRichiesteScaduteDocument, variables, requestHeaders));
    },
    creaDipendente(variables: CreaDipendenteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreaDipendenteMutation> {
      return withWrapper(() => client.request<CreaDipendenteMutation>(CreaDipendenteDocument, variables, requestHeaders));
    },
    creaRichiesta(variables: CreaRichiestaMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreaRichiestaMutation> {
      return withWrapper(() => client.request<CreaRichiestaMutation>(CreaRichiestaDocument, variables, requestHeaders));
    },
    createLoginToken(variables: CreateLoginTokenMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateLoginTokenMutation> {
      return withWrapper(() => client.request<CreateLoginTokenMutation>(CreateLoginTokenDocument, variables, requestHeaders));
    },
    createRole(variables: CreateRoleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateRoleMutation> {
      return withWrapper(() => client.request<CreateRoleMutation>(CreateRoleDocument, variables, requestHeaders));
    },
    createUser(variables?: CreateUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateUserMutation> {
      return withWrapper(() => client.request<CreateUserMutation>(CreateUserDocument, variables, requestHeaders));
    },
    impersonates(variables: ImpersonatesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ImpersonatesMutation> {
      return withWrapper(() => client.request<ImpersonatesMutation>(ImpersonatesDocument, variables, requestHeaders));
    },
    importaDipendenti(variables: ImportaDipendentiMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ImportaDipendentiMutation> {
      return withWrapper(() => client.request<ImportaDipendentiMutation>(ImportaDipendentiDocument, variables, requestHeaders));
    },
    pingMutation(variables?: PingMutationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PingMutationMutation> {
      return withWrapper(() => client.request<PingMutationMutation>(PingMutationDocument, variables, requestHeaders));
    },
    recoverPassword(variables: RecoverPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RecoverPasswordMutation> {
      return withWrapper(() => client.request<RecoverPasswordMutation>(RecoverPasswordDocument, variables, requestHeaders));
    },
    resetPassword(variables: ResetPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ResetPasswordMutation> {
      return withWrapper(() => client.request<ResetPasswordMutation>(ResetPasswordDocument, variables, requestHeaders));
    },
    rimuoviDipendente(variables: RimuoviDipendenteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RimuoviDipendenteMutation> {
      return withWrapper(() => client.request<RimuoviDipendenteMutation>(RimuoviDipendenteDocument, variables, requestHeaders));
    },
    rimuoviRichiesta(variables: RimuoviRichiestaMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RimuoviRichiestaMutation> {
      return withWrapper(() => client.request<RimuoviRichiestaMutation>(RimuoviRichiestaDocument, variables, requestHeaders));
    },
    updateLoginToken(variables: UpdateLoginTokenMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateLoginTokenMutation> {
      return withWrapper(() => client.request<UpdateLoginTokenMutation>(UpdateLoginTokenDocument, variables, requestHeaders));
    },
    updateSelfPassword(variables: UpdateSelfPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateSelfPasswordMutation> {
      return withWrapper(() => client.request<UpdateSelfPasswordMutation>(UpdateSelfPasswordDocument, variables, requestHeaders));
    },
    updateUser(variables: UpdateUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateUserMutation> {
      return withWrapper(() => client.request<UpdateUserMutation>(UpdateUserDocument, variables, requestHeaders));
    },
    updateUserPassword(variables: UpdateUserPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateUserPasswordMutation> {
      return withWrapper(() => client.request<UpdateUserPasswordMutation>(UpdateUserPasswordDocument, variables, requestHeaders));
    },
    basicLogin(variables: BasicLoginQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BasicLoginQuery> {
      return withWrapper(() => client.request<BasicLoginQuery>(BasicLoginDocument, variables, requestHeaders));
    },
    dipendente(variables: DipendenteQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DipendenteQuery> {
      return withWrapper(() => client.request<DipendenteQuery>(DipendenteDocument, variables, requestHeaders));
    },
    dipendenti(variables?: DipendentiQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DipendentiQuery> {
      return withWrapper(() => client.request<DipendentiQuery>(DipendentiDocument, variables, requestHeaders));
    },
    dipendentiBatch(variables?: DipendentiBatchQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DipendentiBatchQuery> {
      return withWrapper(() => client.request<DipendentiBatchQuery>(DipendentiBatchDocument, variables, requestHeaders));
    },
    downloadAudio(variables: DownloadAudioQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadAudioQuery> {
      return withWrapper(() => client.request<DownloadAudioQuery>(DownloadAudioDocument, variables, requestHeaders));
    },
    getGroups(variables?: GetGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetGroupsQuery> {
      return withWrapper(() => client.request<GetGroupsQuery>(GetGroupsDocument, variables, requestHeaders));
    },
    getLogsAccessi(variables?: GetLogsAccessiQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetLogsAccessiQuery> {
      return withWrapper(() => client.request<GetLogsAccessiQuery>(GetLogsAccessiDocument, variables, requestHeaders));
    },
    getLogsSequenze(variables?: GetLogsSequenzeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetLogsSequenzeQuery> {
      return withWrapper(() => client.request<GetLogsSequenzeQuery>(GetLogsSequenzeDocument, variables, requestHeaders));
    },
    getNewAccessToken(variables: GetNewAccessTokenQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetNewAccessTokenQuery> {
      return withWrapper(() => client.request<GetNewAccessTokenQuery>(GetNewAccessTokenDocument, variables, requestHeaders));
    },
    getPolicies(variables?: GetPoliciesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetPoliciesQuery> {
      return withWrapper(() => client.request<GetPoliciesQuery>(GetPoliciesDocument, variables, requestHeaders));
    },
    getSequenze(variables?: GetSequenzeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetSequenzeQuery> {
      return withWrapper(() => client.request<GetSequenzeQuery>(GetSequenzeDocument, variables, requestHeaders));
    },
    loggedUser(variables?: LoggedUserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LoggedUserQuery> {
      return withWrapper(() => client.request<LoggedUserQuery>(LoggedUserDocument, variables, requestHeaders));
    },
    ottieniDestinatari(variables: OttieniDestinatariQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OttieniDestinatariQuery> {
      return withWrapper(() => client.request<OttieniDestinatariQuery>(OttieniDestinatariDocument, variables, requestHeaders));
    },
    ping(variables?: PingQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PingQuery> {
      return withWrapper(() => client.request<PingQuery>(PingDocument, variables, requestHeaders));
    },
    richiesta(variables: RichiestaQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RichiestaQuery> {
      return withWrapper(() => client.request<RichiestaQuery>(RichiestaDocument, variables, requestHeaders));
    },
    richieste(variables?: RichiesteQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RichiesteQuery> {
      return withWrapper(() => client.request<RichiesteQuery>(RichiesteDocument, variables, requestHeaders));
    },
    richiesteAperte(variables?: RichiesteAperteQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RichiesteAperteQuery> {
      return withWrapper(() => client.request<RichiesteAperteQuery>(RichiesteAperteDocument, variables, requestHeaders));
    },
    richiesteMensili(variables?: RichiesteMensiliQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RichiesteMensiliQuery> {
      return withWrapper(() => client.request<RichiesteMensiliQuery>(RichiesteMensiliDocument, variables, requestHeaders));
    },
    richiesteOdierne(variables?: RichiesteOdierneQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RichiesteOdierneQuery> {
      return withWrapper(() => client.request<RichiesteOdierneQuery>(RichiesteOdierneDocument, variables, requestHeaders));
    },
    richiesteSettimanali(variables?: RichiesteSettimanaliQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RichiesteSettimanaliQuery> {
      return withWrapper(() => client.request<RichiesteSettimanaliQuery>(RichiesteSettimanaliDocument, variables, requestHeaders));
    },
    roles(variables?: RolesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RolesQuery> {
      return withWrapper(() => client.request<RolesQuery>(RolesDocument, variables, requestHeaders));
    },
    user(variables: UserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UserQuery> {
      return withWrapper(() => client.request<UserQuery>(UserDocument, variables, requestHeaders));
    },
    users(variables?: UsersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UsersQuery> {
      return withWrapper(() => client.request<UsersQuery>(UsersDocument, variables, requestHeaders));
    },
    validateSSOToken(variables: ValidateSsoTokenQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ValidateSsoTokenQuery> {
      return withWrapper(() => client.request<ValidateSsoTokenQuery>(ValidateSsoTokenDocument, variables, requestHeaders));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;