import type { FC } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Alert,
    Box,
    Divider,
    Button,
    Link,
    FormHelperText,
    TextField, Toolbar
} from '@material-ui/core';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { Link as RouterLink, useHref } from 'react-router-dom';

import { login } from "./commands.login";
import { useTranslation } from "react-i18next";
import LanguagePopover from "../../material/dashboard/LanguagePopover";
import React from "react";

const LoginPage: FC = (props) => {
    const isMountedRef = useIsMountedRef();
    const { t } = useTranslation();
    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
                submit: null
            }}
            validationSchema={
                Yup
                    .object()
                    .shape({
                        email: Yup
                            .string()
                            .max(255)
                            .required(t("requiredField")),
                        password: Yup
                            .string()
                            .max(255)
                            .required(t("requiredField"))
                    })
            }
            onSubmit={async (values, {
                setErrors,
                setStatus,
                setSubmitting
            }): Promise<void> => {
                const res = await login(values.email, values.password);
                if (res.logged === true) {
                    if (isMountedRef.current) {
                        setStatus({ success: true });
                        setSubmitting(false);
                    }
                } else {
                    if (isMountedRef.current) {
                        setStatus({ success: false });
                        setErrors({ submit: res.message });
                        setSubmitting(false);
                    }
                }

            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
            }): JSX.Element => (
                <form
                    noValidate
                    onSubmit={handleSubmit}
                    {...props}
                >
                    <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Email Address"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                    />
                    <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="Password"
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                    />
                    {errors.submit && (
                        <Box sx={{ mt: 3 }}>
                            <FormHelperText error>
                                {errors.submit}
                            </FormHelperText>
                        </Box>
                    )}
                    <Box sx={{ mt: 2 }}>
                        <Button
                            color="primary"
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                        >
                            {t("loginBtn")}
                        </Button>

                        <Button
                            sx={{ marginTop: '10px' }}
                            color="secondary"
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            variant="contained"
                            href={"/sso"}
                        >
                            {t("loginSSO")}
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

export default LoginPage;
