import {useEffect, useState} from 'react';
import type {FC} from 'react';
import {Link as RouterLink, useNavigate, useLocation, useParams} from 'react-router-dom';
import * as Yup from 'yup';
import {ErrorMessage, Formik} from 'formik';
import {useSnackbar} from 'notistack';
import {
    Box, Breadcrumbs,
    Card,
    CardContent,
    CardHeader,
    Container,
    Grid, Link,
    Typography
} from '@material-ui/core';
import useSettings from "../../hooks/useSettings";
import {Helmet} from "react-helmet-async";
import ChevronRightIcon from "../../icons/ChevronRight";
import ArrowLeftIcon from "../../icons/ArrowLeft";
import GraphqlClient from "../../client/graphql.client";
import {Button} from 'src/components/Button/Button'
import {TextField, TextFieldPassword} from 'src/components/TextField/TextField'
import {useSelector} from "../../store";
import {Autocomplete} from "@material-ui/lab";
import {Role, User} from "../../types/generated";

const NewGroup: FC = (props) => {

    const roles = useSelector(state => state.roles.data);
    const users = useSelector(state => state.users.data);

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const {settings} = useSettings();
    const {enqueueSnackbar} = useSnackbar();

    return (
        <>
            <Helmet>
                <title>Dashboard: Nuovo gruppo </title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Nuovo ruolo
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small"/>}
                                sx={{mt: 1}}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/roles?tab?groups"
                                    variant="subtitle2"
                                >
                                    Gruppi
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Nuovo gruppo
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Box sx={{m: -1}}>
                                <Button
                                    color="primary"
                                    component={RouterLink}
                                    startIcon={<ArrowLeftIcon fontSize="small"/>}
                                    sx={{mt: 1}}
                                    to="/users"
                                    variant="outlined"
                                    label='Indietro'
                                />
                                {/*  Indietro
                                </Button_MUI> */}
                            </Box>
                        </Grid>
                    </Grid>

                    <Box sx={{mt: 3}}>
                        <Formik
                            initialValues={{
                                subject: "",
                                role: '',
                                submit: null
                            }}
                            validationSchema={
                                Yup
                                    .object()
                                    .shape({
                                        subject: Yup.string().max(100).required('Campo obbligatorio'),
                                        role: Yup.string().max(40).required("Campo ogglibgatorio"),
                                    })
                            }
                            onSubmit={async (values, {
                                setErrors,
                                setStatus,
                                setSubmitting
                            }): Promise<void> => {
                                setSubmitting(true);
                                let {subject, role} = values;
                                try {
                                    await GraphqlClient.AddGroup({subject, role});
                                    setStatus({success: true});
                                    setSubmitting(false);
                                    enqueueSnackbar('Gruppo creato con successo', {
                                        anchorOrigin: {
                                            horizontal: 'right',
                                            vertical: 'top'
                                        },
                                        variant: 'success'
                                    });
                                    navigate('/roles?tab=groups');
                                } catch (e) {
                                    console.error(e);
                                    setStatus({success: false});
                                    setSubmitting(false);
                                    enqueueSnackbar("Errore durante creazione del gruppo", {
                                        anchorOrigin: {
                                            horizontal: 'right',
                                            vertical: 'top'
                                        },
                                        variant: 'error'
                                    });
                                    return;
                                }


                            }}
                        >
                            {({
                                  errors,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue,
                                  touched,
                                  values
                              }): JSX.Element => (
                                <form
                                    onSubmit={handleSubmit}
                                    {...props}
                                >
                                    <Box sx={{p: 3}}>

                                        <Grid
                                            container
                                            spacing={3}
                                        >
                                            <Grid
                                                item
                                                md={12}
                                                xs={12}
                                            >
                                                <Card>
                                                    <CardContent>
                                                        <CardHeader title="Informazioni ruolo"/>
                                                        <Box sx={{mt: 2}}>

                                                            <Autocomplete
                                                                disableClearable
                                                                onChange={(_, newValue: User) => {
                                                                    if (!newValue) {
                                                                        setFieldValue('subject', "")
                                                                        return
                                                                    }
                                                                    setFieldValue('subject', newValue?.email)
                                                                }}

                                                                options={users}
                                                                getOptionLabel={(option) => (option.email)}
                                                                renderInput={(params) => <TextField {...params}
                                                                                                    label="Utente"
                                                                                                    variant="outlined"/>}
                                                            />
                                                            <ErrorMessage name="subject" component="div"/>


                                                            <Autocomplete
                                                                disableClearable
                                                                onChange={(_, newValue: Role) => {
                                                                    if (!newValue) {
                                                                        setFieldValue('role', "")
                                                                        return
                                                                    }
                                                                    setFieldValue('role', newValue?.name)
                                                                }}
                                                                options={roles}
                                                                getOptionLabel={(option) => option.name}
                                                                renderInput={(params) => <TextField {...params}
                                                                                                    label="Ruolo"
                                                                                                    variant="outlined"/>}
                                                            />
                                                            <ErrorMessage name="role" component="div"/>

                                                        </Box>
                                                    </CardContent>

                                                </Card>
                                            </Grid>
                                        </Grid>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                mt: 3
                                            }}
                                        >
                                            <Button
                                                color="primary"
                                                disabled={isSubmitting}
                                                type="submit"
                                                variant="contained"
                                                label='Salva'
                                            />
                                            {/* Crea utente
                                            </Button> */}
                                        </Box>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default NewGroup;
