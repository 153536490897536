// @ts-nocheck
import React, { useEffect, useState, Fragment } from 'react';
import type { FC, ChangeEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {
    Box, Breadcrumbs,
    Card,
    Tooltip,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    /* TextField, */ Typography, CircularProgress, Toolbar, 
    Dialog, DialogActions, DialogContent
} from '@material-ui/core';
import { Table, DatePickerRangeFilter, DatePickerRangeFilterMethod } from 'src/components/Table/Table'


import ArrowRightIcon from '../../icons/ArrowRight';
import PencilAltIcon from '../../icons/PencilAlt';
import SearchIcon from '../../icons/Search';
import Scrollbar from '../../material/Scrollbar';
import { Helmet } from "react-helmet-async";
import ChevronRightIcon from "../../icons/ChevronRight";
import PlusIcon from "../../icons/Plus";
import useSettings from "../../hooks/useSettings";
import { useSelector } from "../../store";
import { importDipendenti, readAll } from "./commands.dipendenti";
import PlayCircleOutlineSharpIcon from '@material-ui/icons/PlayCircleOutlineSharp';


/*****************************************/
import Button_MUI from '@material-ui/core/Button';
import { Button } from '../../components/Button/Button'
import { TextField, TextFieldPassword } from '../../components/TextField/TextField'
import PermissionGuard from "../../components/PermissionGuard/PermissionGuard";
import dayjs from 'dayjs';
import Label from 'src/material/Label';
/*****************************************/
const wait = (time: number): Promise<void> => new Promise((res) => setTimeout(res, time));

const PaginaDipendenti: FC = (props) => {
    const dipendenti = useSelector(state => state.dipendenti.data);
    const user = useSelector(state => (state as any).auth);

    const { settings } = useSettings();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { ...other } = props;

    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [importValue, setImportValue] = useState<string>("")

    const handleImport = async () => {
        let base64 = btoa(importValue)
        let res = await importDipendenti(base64)
        if (res.error) {
            enqueueSnackbar("Errore: " + res.message, {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } else {
            enqueueSnackbar("Dipendenti caricati", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success'
            });
            importValue = ""
            readAll()
            setOpenDialog(false)
        }
    }

    useEffect(() => {
        readAll()
    }, [])

    // Usually query is done on backend with indexing solutions
    // @ts-ignore
    return (
        <>
            <Helmet>
                <title>Dashboard: Dipendenti </title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Dipendenti
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Dipendenti
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <PermissionGuard action={'allow'} resource={'importaDipendenti'}>
                            <Grid item>
                                <Box sx={{ m: -1 }}>
                                    <Button
                                        color="primary"
                                        startIcon={<PlusIcon fontSize="small" />}
                                        sx={{ m: 1 }}
                                        variant="contained"
                                        label='Importa dipendenti'
                                        onClick={() => setOpenDialog(true)}
                                    />
                                </Box>
                            </Grid>
                        </PermissionGuard>
                        <Dialog 
                            open={openDialog} 
                            onClose={() => setOpenDialog(false)} 
                            aria-labelledby="form-import"
                            maxWidth="md"
                            fullWidth
                        >
                            <DialogContent>
                            <TextField 
                                fullWidth 
                                multiline 
                                rows={10}
                                value={importValue}
                                onChange={(e) => setImportValue(e.target.value)}
                            />
                            </DialogContent>
                            <DialogActions>
                            <Button
                                disabled={importValue.length == 0}
                                color="primary"
                                startIcon={<PlusIcon fontSize="small" />}
                                sx={{ m: 1 }}
                                variant="contained"
                                label='Importa'
                                onClick={() => handleImport()}
                            />
                            </DialogActions>
                        </Dialog>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Card {...other}>
                            <Scrollbar>
                                <Box sx={{ minWidth: 1200 }}>
                                    <Table
                                        Data={dipendenti ?? []}
                                        Columns={
                                            [
                                                {
                                                    Header: 'CID',
                                                    accessor: 'cid',
                                                },
                                                {
                                                    Header: 'Nome',
                                                    accessor: 'nome',
                                                },
                                                {
                                                    Header: 'Cognome',
                                                    accessor: 'cognome',
                                                },
                                                {
                                                    Header: 'Email',
                                                    accessor: 'email',
                                                    Cell: cell => (
                                                        <Label>{cell.value}</Label>
                                                    )
                                                },
                                                {
                                                    Header: 'Data Nascita',
                                                    accessor: 'dataNascita',
                                                    disableFilters: true,
                                                    Cell: row => (row.value ? dayjs.unix(row.value).format('DD/MM/YYYY') : null),
                                                },
                                            ]}
                                    />
                                </Box>
                            </Scrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>
        </>
    )
        ;
};

export default PaginaDipendenti;
