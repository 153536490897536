const casbinjs = require("casbin.js");

let authorizer = undefined;

export const SetPermissions = async (perms) => {
    authorizer = new casbinjs.Authorizer("manual")
    await authorizer.setPermission(perms);
}

export const Can = async (action, resource) => {
    if (!authorizer) return false;

    let result = await authorizer.can(action, resource);
    return result;
}
