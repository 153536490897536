import React, {useEffect, useState} from 'react';
import type {FC} from 'react';
import {
    Badge,
    Box,
    Button,
    Drawer,
    Fab,
    FormControlLabel, IconButton,
    Switch,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import {THEMES} from '../constants';
import useSettings from '../hooks/useSettings';
import AdjustmentsIcon from '../icons/Adjustments';
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import BellIcon from "../icons/Bell";
import {useTranslation} from "react-i18next";

const getValues = (settings) => (
    {
        compact: settings.compact,
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        roundedCorners: settings.roundedCorners,
        theme: settings.theme
    }
);

const SettingsDrawer: FC = () => {
    const {settings, saveSettings} = useSettings();
    const [open, setOpen] = useState<boolean>(false);
    const [values, setValues] = useState(getValues(settings));

    const {t} = useTranslation();

    useEffect(() => {
        setValues(getValues(settings));
    }, [settings]);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleChange = (field, value): void => {
        setValues({
            ...values,
            [field]: value
        });
    };

    const handleSave = (): void => {
        saveSettings(values);
        setOpen(false);
    };

    return (
        <>

            <Tooltip title="Settings">
                <IconButton
                    color="inherit"
                    onClick={handleOpen}
                >

                    <AdjustmentsIcon fontSize="small"/>
                </IconButton>
            </Tooltip>
            <Drawer
                anchor="right"
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: {
                        p: 2,
                        width: 320
                    }
                }}
            >
                <Typography
                    color="textPrimary"
                    variant="h6"
                >
                    Impostazioni
                </Typography>
                <Box sx={{mt: 3}}>
                    <TextField
                        fullWidth
                        label={t("theme")}
                        name="theme"
                        onChange={(event): void => handleChange(
                            "theme",
                            event.target.value
                        )}
                        select
                        SelectProps={{native: true}}
                        value={values.theme}
                        variant="outlined"
                    >
                        {Object.keys(THEMES).map((theme) => (
                            <option
                                key={theme}
                                value={theme}
                            >
                                {
                                    t(theme)
                                }
                            </option>
                        ))}
                    </TextField>
                </Box>
                {/* <Box
                    sx={{
                        mt: 2,
                        px: 1.5
                    }}
                >
                    <FormControlLabel
                        control={(
                            <Switch
                                checked={values.direction === 'rtl'}
                                color="primary"
                                edge="start"
                                name="direction"
                                onChange={(event): void => handleChange(
                                    'direction',
                                    event.target.checked
                                        ? 'rtl'
                                        : 'ltr'
                                )}
                            />
                        )}
                        label={(
                            <div>
                                RTL
                                <Typography
                                    color="textSecondary"
                                    component="p"
                                    variant="caption"
                                >
                                    {t("rtl")}
                                </Typography>
                            </div>
                        )}
                    />
                </Box> */}
                <Box
                    sx={{
                        mt: 2,
                        px: 1.5
                    }}
                >
                    <FormControlLabel
                        control={(
                            <Switch
                                checked={values.responsiveFontSizes}
                                color="primary"
                                edge="start"
                                name="direction"
                                onChange={(event): void => handleChange(
                                    'responsiveFontSizes',
                                    event.target.checked
                                )}
                            />
                        )}
                        label={(
                            <div>
                                {t("responsiveFont")}

                                <Typography
                                    color="textSecondary"
                                    component="p"
                                    variant="caption"
                                >
                                    {t("responsiveFontDescription")}
                                </Typography>
                            </div>
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        mt: 2,
                        px: 1.5
                    }}
                >
                    <FormControlLabel
                        control={(
                            <Switch
                                checked={values.compact}
                                color="primary"
                                edge="start"
                                name="compact"
                                onChange={(event): void => handleChange(
                                    'compact',
                                    event.target.checked
                                )}
                            />
                        )}
                        label={(
                            <div>
                                {t("compact")}
                                <Typography
                                    color="textSecondary"
                                    component="p"
                                    variant="caption"
                                >
                                    {t("compactDescription")}

                                </Typography>
                            </div>
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        mt: 2,
                        px: 1.5
                    }}
                >
                    <FormControlLabel
                        control={(
                            <Switch
                                checked={values.roundedCorners}
                                color="primary"
                                edge="start"
                                name="roundedCorners"
                                onChange={(event): void => handleChange(
                                    'roundedCorners',
                                    event.target.checked
                                )}
                            />
                        )}
                        label={(
                            <div>
                                {t("roundBorders")}
                                <Typography
                                    color="textSecondary"
                                    component="p"
                                    variant="caption"
                                >
                                    {t("roundBordersDescription")}
                                </Typography>
                            </div>
                        )}
                    />
                </Box>
                <Box sx={{mt: 3}}>
                    <Button
                        color="primary"
                        fullWidth
                        onClick={handleSave}
                        variant="contained"
                    >
                        {t("saveSettings")}
                    </Button>
                </Box>
            </Drawer>
        </>
    );
};

export default SettingsDrawer;
