import type {FC} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {AppBar, Box, Hidden, IconButton, Toolbar, Tooltip} from '@material-ui/core';
import {experimentalStyled} from '@material-ui/core/styles';
import type {AppBarProps} from '@material-ui/core';
import MenuIcon from '../icons/Menu';
import AccountPopover from './dashboard/AccountPopover';
import ContentSearch from './dashboard/ContentSearch';
import LanguagePopover from './dashboard/LanguagePopover';
import Logo from './Logo';
import NotificationsPopover from './dashboard/NotificationsPopover';
import SettingsDrawer from "./SettingsDrawer";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import React from "react";
import {removeImpersonation} from "../pages/login/commands.login";
import {useSelector} from "react-redux";

interface DashboardNavbarProps extends AppBarProps {
    onSidebarMobileOpen?: () => void;
    openSidebar?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(
    ({theme}) => (
        {
            ...(
                theme.palette.mode === 'light' && {
                    backgroundColor: theme.palette.primary.main,
                    boxShadow: 'none',
                    color: theme.palette.primary.contrastText
                }
            ),
            ...(
                theme.palette.mode === 'dark' && {
                    backgroundColor: theme.palette.background.paper,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    boxShadow: 'none'
                }
            ),
            zIndex: theme.zIndex.drawer + 100
        }
    )
);

const NavBar: FC<DashboardNavbarProps> = (props) => {
    const {onSidebarMobileOpen, openSidebar, ...other} = props;
    const user = useSelector(state => (state as any).auth);

    return (
        <DashboardNavbarRoot {...other}>
            <Toolbar sx={{minHeight: 64}}>
                <Hidden lgUp>
                    <IconButton
                        color="inherit"
                        onClick={onSidebarMobileOpen}
                    >
                        <MenuIcon fontSize="small"/>
                    </IconButton>
                </Hidden>
                <Hidden lgDown>
                    <IconButton
                        color="inherit"
                        onClick={openSidebar}
                    >
                        <MenuIcon fontSize="small"/>
                    </IconButton>
                </Hidden>

                <Box
                    sx={{
                        flexGrow: 1,
                        ml: 2
                    }}
                />
                {/* <LanguagePopover /> */}

                {
                    user?.impersonificated &&
                    <Box sx={{ml: 1}}>
                        <Tooltip title={`Impersonificazione di "${user?.user?.email}" in corso, clicca per terminare`}>
                            <IconButton className={"impersonificationIcon"} onClick={() => removeImpersonation()}>
                                <SupervisedUserCircleIcon fontSize="small"/>
                            </IconButton>
                        </Tooltip>
                    </Box>}
                <SettingsDrawer></SettingsDrawer>
                <Box sx={{ml: 2}}>
                    <AccountPopover/>
                </Box>
            </Toolbar>
        </DashboardNavbarRoot>
    );
};

NavBar.propTypes = {
    onSidebarMobileOpen: PropTypes.func
};

export default NavBar;
