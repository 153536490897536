import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import AuthGuard from './material/AuthGuard';
import DashboardLayout from './material/dashboard/DashboardLayout';
import GuestGuard from './material/GuestGuard';
import LoadingScreen from './material/LoadingScreen';
import PageUsers from "./pages/users/page.user";
import NewUser from "./pages/users/new.user";
import UpdateUser from "./pages/users/update.user";
import PageRole from "./pages/roles/page.role";
import NewRole from "./pages/roles/new.role";
import NewPolicy from "./pages/roles/new.policy";
import PermissionGuard from "./components/PermissionGuard/PermissionGuard";
import PaginaRichieste from "./pages/richieste/pagina.richieste"
import PaginaDipendenti from "./pages/dipendenti/pagina.dipendenti"
import PasswordRecovery from "./pages/authentication/password-recovery/PasswordRecovery";
import PasswordReset from "./pages/authentication/password-reset/PasswordReset";
import NewGroup from "./pages/roles/new.group";
import PaginaDevelopers from './pages/developers/page.developers';
import PaginaLogAccessi from './pages/logs/pagina.logaccessi';
import PaginaLogSequenze from './pages/logs/pagina.logsequenze';
// YEOMAN IMPORT ANCHOR */

const Loadable = (Component) => (props) => (
    <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
    </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/account/pagina.account')));
const Finance = Loadable(lazy(() => import('./pages/dashboard/pagina.dashboard')));


const routes: PartialRouteObject[] = [
    {
        path: 'authentication',
        children: [
            {
                path: 'login',
                element: (
                    <GuestGuard>
                        <Login />
                    </GuestGuard>
                )
            },
            // {
            //     path: 'register',
            //     element: (
            //         <GuestGuard>
            //             <Register />
            //         </GuestGuard>
            //     )
            // },
            // {
            //     path: 'register-unguarded',
            //     element: <Register />
            // },
            // {
            //     path: 'password-recovery',
            //     element: <PasswordRecovery />
            // },
            // {
            //     path: 'password-reset',
            //     element: <PasswordReset />
            // },
        ]
    },
    {
        path: '*',
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: '/',
                element: <Finance />
            },
            {
                path: 'account',
                element: <Account />
            },
            {
                path: 'users',
                children: [
                    {
                        path: '/',
                        element:
                            <PermissionGuard action={"allow"} resource={"users"}>
                                <PageUsers />
                            </PermissionGuard>
                    },
                    {
                        path: 'new',
                        element: <PermissionGuard action={"allow"} resource={"createUser"}>
                            <NewUser />
                        </PermissionGuard>
                    },
                    {
                        path: 'modifica/:id',
                        element: <PermissionGuard action={"allow"} resource={"updateUser"}>
                            <UpdateUser />
                        </PermissionGuard>
                    }
                ]
            },
            {
                path: 'roles',
                children: [
                    {
                        path: '/',
                        element: <PageRole />
                    },
                    {
                        path: 'new/ruolo',
                        element: <NewRole />
                    },
                    {
                        path: 'new/gruppo',
                        element: <NewGroup />
                    },
                    {
                        path: 'nuova/policy',
                        element: <NewPolicy />
                    },
                ]
            },
            {
                path: 'richieste',
                children: [
                    {
                        path: '/',
                        element:
                            <PermissionGuard action={"allow"} resource={"basic"}>
                                <PaginaRichieste />
                            </PermissionGuard>
                    },
                ]
            },
            {
                path: 'dipendenti',
                children: [
                    {
                        path: '/',
                        element:
                            <PermissionGuard action={"allow"} resource={"basic"}>
                                <PaginaDipendenti />
                            </PermissionGuard>
                    },
                ]
            },
            {
                path: 'logs',
                children: [
                    {
                        path: 'accessi',
                        element:
                            <PermissionGuard action={"allow"} resource={"basic"}>
                                <PaginaLogAccessi />
                            </PermissionGuard>
                    },
                    {
                        path: 'sequenze',
                        element:
                            <PermissionGuard action={"allow"} resource={"basic"}>
                                <PaginaLogSequenze />
                            </PermissionGuard>
                    },
                ]
            },
            {
                path: 'developers',
                children: [
                    {
                        path: '/',
                        element:
                            <PermissionGuard action={"allow"} resource={"endpoints"}>
                                <PaginaDevelopers />
                            </PermissionGuard>
                    },
                ]
            }
            // YEOMAN ANCHOR */
        ]
    },
];

export default routes;
