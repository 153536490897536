import { useSnackbar } from 'notistack';
import {
  Box, Breadcrumbs,
  Card,
  Tooltip,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField, Typography, CardContent, CardHeader, Button, CardActions
} from '@material-ui/core';
import useSettings from 'src/hooks/useSettings';
import Scrollbar from '../../material/Scrollbar';
import { Helmet } from "react-helmet-async";
import ChevronRightIcon from "../../icons/ChevronRight";
import { Link as RouterLink } from 'react-router-dom';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism-okaidia.css';
import { useEffect, useState } from 'react';
import hjson from 'hjson';

interface Props {

}

const loginObj = {
  req: `{
  "cid": "11111",
  "dataNascita": "17021995" // ggmmyyyy
}`, resp: `{
  "cid": "11111",
  "created_at": 1631093607,
  "nome": "Lucia",
  "cognome": "Bianchi",
  "data_nascita": 792979200,
  "email": "lucia.bianchi@email.it",
  "notificationCid": "00001",
  "richieste": null
}`}

const nuovaObj = {
  req: `{
  "cid": "11111",
  "inizio": 1631021166, // timestamp unix
  "giorni": 4,
  "certificato": false,
  "audio": null // stringa base64
}`,
  resp: ``
}

const aggiornaObj = {
  req: `{
  "cid": "99999",
  "giorni": 2, // giorni per cui proseguire la malattia, null per non aggiornare il campo
  "audio": null, // stringa base64, null per non aggiornare il campo
  "certificato": true
}`,
  resp: ``
}

const chiudiObj = {
  req: `{
  "cid": "11111"
}`,
  resp: `false`
}

const pendingObj = {
  req: `{
  "cid": "11111"
}`,
  resp: `true`
}

const PaginaDevelopers = (props: Props) => {
  const { settings } = useSettings();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [login, setLogin] = useState(loginObj)
  const [nuova, setNuova] = useState(nuovaObj)
  const [aggiorna, setAggiorna] = useState(aggiornaObj)
  const [chiudi, setChiudi] = useState(chiudiObj)
  const [pending, setPending] = useState(pendingObj)
  const { ...other } = props;

  function runAPI(endpoint: string, state) {
    let [value, setter] = state
    postData(endpoint, hjson.parse(value.req))
      .then(data => {
        if (data.status != 200) {
          data.text().then(o => setter({ ...value, resp: o }))
        } else {
          data.json().then(o => setter({ ...value, resp: JSON.stringify(o, null, 2) }))
        }
      })
  }

  async function postData(url: string, data: {}) {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': 'YcFE!966q%eem@?VAM&2gg'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data)
    });
    return response;
  }

  return (
    <>
      <Helmet>
        <title>Endpoints IVR</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Endpoints IVR
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Endpoints
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Custom Header
                    </Typography>
                    <Typography variant="h6">
                      X-API-KEY: YcFE!966q%eem@?VAM&2gg
                    </Typography>

                    <Typography variant="h6" marginTop="30px">
                      URL di Prod:
                      https://allianzivr.testudosrl.com
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Utenti Test
                    </Typography>
                    <Typography variant="body1">
                      {`{"cid":"11111","dataNascita":"17021995"}`}
                    </Typography>
                    <Typography variant="body1">
                      {`{"cid":"22222","dataNascita":"06041995"}`}
                    </Typography>
                    <Typography variant="body1">
                      {`{"cid":"33333","dataNascita":"15051977"}`}
                    </Typography>
                    <Typography variant="body1">
                      {`{"cid":"44444","dataNascita":"14061988"}`}
                    </Typography>
                    <Typography variant="body1">
                      {`{"cid":"99999","dataNascita":"15051977"}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Card variant="outlined">
                      <CardHeader title="POST /api/login" />
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <Editor
                              value={login.req}
                              onValueChange={code => setLogin({ ...login, req: code })}
                              highlight={code => highlight(code, languages.json, 'json')}
                              padding={10}
                              style={{
                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                fontSize: 12,
                                background: '#252A36',
                                border: '1px solid black',
                                height: '100%'
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Editor
                              disabled
                              value={login.resp}
                              onValueChange={code => setLogin({ ...login, resp: code })}
                              highlight={code => highlight(code, languages.json, 'json')}
                              padding={10}
                              style={{
                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                fontSize: 12,
                                background: '#252A36',
                                border: '1px solid black',
                              }}
                            />
                          </Grid>
                        </Grid>
                        <CardActions>
                          <Button
                            variant="contained"
                            onClick={(e) => runAPI("/api/login", [login, setLogin])}
                          >
                            Esegui
                          </Button>
                        </CardActions>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card variant="outlined">
                      <CardHeader title="POST /api/nuova" />
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <Editor
                              value={nuova.req}
                              onValueChange={code => setNuova({ ...nuova, req: code })}
                              highlight={code => highlight(code, languages.json, 'json')}
                              padding={10}
                              style={{
                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                fontSize: 12,
                                background: '#252A36',
                                border: '1px solid black',
                                height: '100%'
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Editor
                              disabled
                              value={nuova.resp}
                              onValueChange={code => setNuova({ ...nuova, resp: code })}
                              highlight={code => highlight(code, languages.json, 'json')}
                              padding={10}
                              style={{
                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                fontSize: 12,
                                background: '#252A36',
                                border: '1px solid black',
                                height: '100%'
                              }}
                            />
                          </Grid>
                        </Grid>
                        <CardActions>
                          <Button
                            variant="contained"
                            onClick={(e) => runAPI("/api/nuova", [nuova, setNuova])}
                          >
                            Esegui
                          </Button>
                        </CardActions>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card variant="outlined">
                      <CardHeader title="POST /api/aggiorna" />
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <Editor
                              value={aggiorna.req}
                              onValueChange={code => setAggiorna({ ...aggiorna, req: code })}
                              highlight={code => highlight(code, languages.json, 'json')}
                              padding={10}
                              style={{
                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                fontSize: 12,
                                background: '#252A36',
                                border: '1px solid black',
                                height: '100%'
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Editor
                              disabled
                              value={aggiorna.resp}
                              onValueChange={code => setAggiorna({ ...aggiorna, resp: code })}
                              highlight={code => highlight(code, languages.json, 'json')}
                              padding={10}
                              style={{
                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                fontSize: 12,
                                background: '#252A36',
                                border: '1px solid black',
                                height: '100%'
                              }}
                            />
                          </Grid>
                        </Grid>
                        <CardActions>
                          <Button
                            variant="contained"
                            onClick={(e) => runAPI("/api/aggiorna", [aggiorna, setAggiorna])}
                          >
                            Esegui
                          </Button>
                        </CardActions>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card variant="outlined">
                      <CardHeader title="POST /api/pending" />
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <Editor
                              value={pending.req}
                              onValueChange={code => setPending({ ...pending, req: code })}
                              highlight={code => highlight(code, languages.json, 'json')}
                              padding={10}
                              style={{
                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                fontSize: 12,
                                background: '#252A36',
                                border: '1px solid black',
                                height: '100%'
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Editor
                              disabled
                              value={pending.resp}
                              onValueChange={code => setPending({ ...pending, resp: code })}
                              highlight={code => highlight(code, languages.json, 'json')}
                              padding={10}
                              style={{
                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                fontSize: 12,
                                background: '#252A36',
                                border: '1px solid black',
                              }}
                            />
                          </Grid>
                        </Grid>
                        <CardActions>
                          <Button
                            variant="contained"
                            onClick={(e) => runAPI("/api/pending", [pending, setPending])}
                          >
                            Esegui
                          </Button>
                        </CardActions>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card variant="outlined">
                      <CardHeader title="POST /api/chiudi" />
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <Editor
                              value={chiudi.req}
                              onValueChange={code => setChiudi({ ...chiudi, req: code })}
                              highlight={code => highlight(code, languages.json, 'json')}
                              padding={10}
                              style={{
                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                fontSize: 12,
                                background: '#252A36',
                                border: '1px solid black',
                                height: '100%'
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Editor
                              disabled
                              value={chiudi.resp}
                              onValueChange={code => setChiudi({ ...chiudi, resp: code })}
                              highlight={code => highlight(code, languages.json, 'json')}
                              padding={10}
                              style={{
                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                fontSize: 12,
                                background: '#252A36',
                                border: '1px solid black',
                                height: '100%'
                              }}
                            />
                          </Grid>
                        </Grid>
                        <CardActions>
                          <Button
                            variant="contained"
                            onClick={(e) => runAPI("/api/chiudi", [chiudi, setChiudi])}
                          >
                            Esegui
                          </Button>
                        </CardActions>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default PaginaDevelopers
