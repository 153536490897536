import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {
  Box, Breadcrumbs,
  Card,
  Container,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import { Table, DatePickerRangeFilter, DatePickerRangeFilterMethod, SelectColumnFilter, SelectColumnFilterMethod } from 'src/components/Table/Table'
import Scrollbar from '../../material/Scrollbar';
import { Helmet } from "react-helmet-async";
import ChevronRightIcon from "../../icons/ChevronRight";
import useSettings from "../../hooks/useSettings";
import dayjs from 'dayjs';
import Label from 'src/material/Label';
import { logAccessi } from './commands.logs';

const PaginaLogAccessi: FC = (props) => {
  const [logs, setLogs] = useState([])

  const { settings } = useSettings();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { ...other } = props;

  useEffect(() => {
    const loadLogs = async () => {
      const res = await logAccessi()
      if (res.error) {
        console.error(res.message)
        return
      }

      setLogs(res.data)
    }

    loadLogs()
  }, [])

  return (
    <>
      <Helmet>
        <title>Dashboard: Logs </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Registro Accessi
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Log Accessi
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Card {...other}>
              <Scrollbar>
                <Box sx={{ minWidth: 1200 }}>
                  <Table
                    idDefaultColumnSort='createdAt'
                    Data={logs ?? []}
                    Columns={
                      [
                        {
                          Header: 'CID',
                          accessor: 'cid',
                        },
                        {
                          Header: 'Effettuato il',
                          accessor: 'createdAt',
                          Filter: DatePickerRangeFilter,
                          filter: DatePickerRangeFilterMethod,
                          Cell: cell => (cell.value ? dayjs.unix(cell.value).format('DD/MM/YYYY HH:mm') : null),
                        },
                        {
                          Header: 'Accesso',
                          accessor: cell => (cell.success ? "Effettuato" : "Fallito"),
                          Filter: SelectColumnFilter,
                          filter: SelectColumnFilterMethod,
                          Cell: cell => (cell.row.original.success ? <Label color="success">Effettuato</Label> : <Label color="error">Fallito</Label>),
                        },
                        {
                          Header: 'Stato',
                          accessor: cell => (cell.pending ? "Da Lavorare" : "OK"),
                          Filter: SelectColumnFilter,
                          filter: SelectColumnFilterMethod,
                          Cell: cell => (cell.row.original.pending ? <Label color="warning">Da Lavorare</Label> : <Label color="primary">OK</Label>),
                        },
                      ]}
                  />
                </Box>
              </Scrollbar>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  )
    ;
};

export default PaginaLogAccessi;
