import store from "../../store";

import {actions} from './redux.dipendenti';
import GraphqlClient from "../../client/graphql.client";

export const readAll = async () => {
    try {
        const res = await GraphqlClient.dipendenti();
        store.dispatch(actions.readedAll(res.dipendenti));
        return {data: res.dipendenti}
    } catch (error) {
        return {error: true, message: JSON.parse(JSON.stringify(error))}
    }
};

export const importDipendenti = async (input: string) => {
    try {
        const res = await GraphqlClient.importaDipendenti({input: input});
        return res.importaDipendenti
    } catch (error) {
        return {error: true, message: JSON.parse(JSON.stringify(error)).response.errors[0].message}
    }
};

