import store from "../../store";

import { actions } from './redux.richieste';
import GraphqlClient from "../../client/graphql.client";
import { NuovaRichiesta } from "src/types/generated";

export const readAll = async () => {
  try {
    const res = await GraphqlClient.richieste();
    store.dispatch(actions.readedAll(res.richieste));
    return { data: res.richieste }
  } catch (error) {
    return { error: true, message: error.toString() }
  }
};

export const downloadAudio = async (id: { id: string }) => {
  try {
    const res = await GraphqlClient.downloadAudio(id);
    return {
      filename: res.downloadAudio.filename,
      base64: res.downloadAudio.base64,
      loaded: true,
    };
  } catch (error) {
    return { loaded: false, message: error.toString() }
  }
};

export const deleteRichiesta = async (id: { id: string }) => {
  try {
    const res = await GraphqlClient.rimuoviRichiesta(id);
    store.dispatch(actions.deleted(res.rimuoviRichiesta));
    return { data: res.rimuoviRichiesta }
  } catch (err) {
    return { error: true, message: err.toString() }
  }
};

export const newRichiesta = async (nr: NuovaRichiesta) => {
  try {
    const res = await GraphqlClient.creaRichiesta({input: nr})
    return { data: res.creaRichiesta }
  } catch (err) {
    return { error: true, message: err.toString() }
  }
}
