// @ts-nocheck
import React, { useEffect, useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {
  Badge,
  Box, Breadcrumbs,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Table, SelectColumnFilter, SelectColumnFilterMethod, DatePickerRangeFilter, DatePickerRangeFilterMethod } from 'src/components/Table/Table'

import Scrollbar from '../../material/Scrollbar';
import { Helmet } from "react-helmet-async";
import ChevronRightIcon from "../../icons/ChevronRight";
import useSettings from "../../hooks/useSettings";
import { useSelector } from "../../store";
import { readAll, downloadAudio, deleteRichiesta, newRichiesta } from "./commands.richieste";
import { makeStyles } from '@material-ui/core/styles';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import PlusIcon from '../../icons/Plus.tsx'

import dayjs from 'dayjs';
import Trash from 'src/icons/Trash';
import PermissionGuard from "../../components/PermissionGuard/PermissionGuard";
import { Button } from 'src/components/Button/Button';
import { TextField } from 'src/components/TextField/TextField';
import { NuovaRichiesta } from 'src/types/generated';
import Label from 'src/material/Label';
import Check from 'src/icons/Check';
import X from 'src/icons/X';
import AlarmIcon from '@material-ui/icons/Alarm';

const useStyles = makeStyles((theme) => ({
  labelIcon: {
    fontSize: '15px'
  },
  badge: {
    height: '15px'
  }
}));

const PaginaRichieste: FC = (props) => {
  const richieste = useSelector(state => state.richieste.data);

  const { settings } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false)
  const classes = useStyles();

  const [cid, setCid] = useState("")
  const [domicilio, setDomicilio] = useState("")
  const [giorni, setGiorni] = useState(0)
  const [inizio, setInizio] = useState("")
  const [play, setPlay] = useState("")
  const [audio, setAudio] = useState<HTMLAudioElement>(null)

  const playAudio = async (id) => {
    setPlay(id.id)
    let res = await HandleDownloadAudio(id)
    let player = new Audio(res)
    player.play()
    player.addEventListener('ended', () => { setPlay("") })
  }

  const handleSubmit = async () => {
    let r = {} as NuovaRichiesta
    r.cid = cid
    r.domicilio = domicilio
    r.giorni = giorni
    r.inizio = new Date(inizio).getTime() / 1000
    const res = await newRichiesta(r)
    if (res.error) {
      console.log(res.message)
      enqueueSnackbar("Errore: controlla la console", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    } else {
      enqueueSnackbar("Richiesta accettata", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'success'
      });
      setOpenDialog(false)
      readAll()
      r.cid = 0
      r.domicilio = ""
      r.giorni = 0
      r.inizio = ""
    }
  }

  const HandleDownloadAudio = async (id: { id: string }) => {
    const file = await downloadAudio(id)
    if (!file.loaded) {
      enqueueSnackbar("Errore: " + file.message, {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    }
    const bytes = atob(file.base64);
    let length = bytes.length;

    let out = new Uint8Array(length);
    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    let myBlob = new Blob([out], { type: "application/octet-stream" });


    const URLblobObj = window.URL.createObjectURL(myBlob);

    return URLblobObj
  }

  const { ...other } = props;

  useEffect(() => {
    readAll();
  }, [])

  // Usually query is done on backend with indexing solutions
  // @ts-ignore
  return (
    <>
      <Helmet>
        <title>Dashboard: Richieste </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Richieste di malattia
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Richieste
                </Typography>
              </Breadcrumbs>
            </Grid>
            <PermissionGuard action={'allow'} resource={'creaRichiesta'}>
              <Grid item>
                <Box sx={{ m: -1 }}>
                  <Button
                    color="primary"
                    startIcon={<PlusIcon fontSize="small" />}
                    sx={{ m: 1 }}
                    variant="contained"
                    label='Crea Richiesta'
                    onClick={() => setOpenDialog(true)}
                  />
                </Box>
              </Grid>
              <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="form-richiesta"
              >
                <DialogContent>
                  <TextField required id="cid" label="CID" type="number" placeholder="12345" onInput={(e) => { setCid(e.target.valueAsNumber) }} />
                  <TextField required id="domicilio" label="Domicilio" placeholder="Via ..." onInput={(e) => { setDomicilio(e.target.value) }} />
                  <TextField required id="giorni" label="Durata" type="number" placeholder="es. 3" onInput={(e) => { setGiorni(e.target.valueAsNumber) }} />
                  <TextField required id="inizio" label="Inizio" type="date" InputLabelProps={{ shrink: true }} onInput={(e) => { setInizio(e.target.value) }} />
                </DialogContent>
                <DialogActions>
                  <Button
                    disabled={cid == 0}
                    color="primary"
                    startIcon={<PlusIcon fontSize="small" />}
                    sx={{ m: 1 }}
                    variant="contained"
                    label='Crea'
                    onClick={() => handleSubmit()}
                    fullwidth
                  />
                </DialogActions>
              </Dialog>
            </PermissionGuard>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Card {...other}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap',
                  m: -1,
                  p: 2
                }}
              >
              </Box>
              <Scrollbar>
                <Table
                  idDefaultColumnSort='numero'
                  Data={richieste ?? []}
                  Columns={
                    [
                      {
                        Header: 'Numero',
                        accessor: 'numero',
                        width: 120
                      },
                      {
                        Header: 'Data Comunicazione',
                        accessor: 'createdAt',
                        Cell: row => {
                          let date = dayjs.unix(row.value).format('DD/MM/YYYY')
                          return date

                        },
                        Filter: DatePickerRangeFilter,
                        filter: DatePickerRangeFilterMethod,
                        width: 200
                      },
                      {
                        Header: 'CID',
                        accessor: 'dipendente.cid',
                        width: 120
                      },
                      {
                        Header: 'Dipendente',
                        accessor: d => `${d.dipendente.cognome} ${d.dipendente.nome}`,
                      },
                      {
                        Header: 'Inizio Malattia',
                        accessor: 'inizio',
                        Cell: row => {
                          let date = dayjs.unix(row.value).format('DD/MM/YYYY')
                          return date
                        },
                        Filter: DatePickerRangeFilter,
                        filter: DatePickerRangeFilterMethod,
                        width: 200
                      },
                      {
                        Header: 'Fine Malattia',
                        accessor: 'fine',
                        Cell: row => {
                          if (row.value) {
                            let date = dayjs.unix(row.value).format('DD/MM/YYYY')
                            return date
                          } else {
                            return "---"
                          }
                        },
                        Filter: DatePickerRangeFilter,
                        filter: DatePickerRangeFilterMethod,
                        width: 200
                      },
                      {
                        Header: 'Origine',
                        accessor: 'origine',
                        Cell: row => {
                          let date = dayjs.unix(row.value).format('DD/MM/YYYY')
                          return date

                        },
                        Filter: DatePickerRangeFilter,
                        filter: DatePickerRangeFilterMethod,
                        width: 200
                      },
                      {
                        Header: 'Stato',
                        accessor: 'stato',
                        id: 'type',
                        width: 100,
                        Cell: cell => {
                          switch (cell.value) {
                            case 'Aperta':
                              return (
                                <Tooltip
                                  arrow
                                  placement='left'
                                  title={
                                    <React.Fragment>
                                      <Typography
                                        color="inherit"
                                        variant="overline"
                                      >
                                        Proroghe
                                      </Typography>
                                      <Box display='flex' flexDirection='column'>
                                        {cell.row.original.proroghe?.map((data) => (
                                          <Typography
                                            color="inherit"
                                            variant="overline"
                                          >
                                            {data}
                                          </Typography>
                                        ))}
                                      </Box>
                                    </React.Fragment>
                                  }
                                >
                                  <Badge
                                    badgeContent={cell.row.original.proroghe?.length}
                                    color="secondary"
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right',
                                    }}
                                  >
                                    <Label color="success">{cell.value}</Label>
                                  </Badge>
                                </Tooltip>
                              )
                            case 'Chiusa':
                              return (
                                <Tooltip
                                  arrow
                                  placement='left'
                                  title={
                                    cell.row.original.proroghe ?
                                      <React.Fragment>
                                        <Typography
                                          color="inherit"
                                          variant="overline"
                                        >
                                          Proroghe
                                        </Typography>
                                        <Grid container>
                                          {cell.row.original.proroghe?.map((data) => (
                                            <Grid item xs={12}>
                                              <Typography
                                                color="inherit"
                                                variant="overline"
                                              >
                                                {data}
                                              </Typography>
                                            </Grid>
                                          ))}
                                        </Grid>
                                      </React.Fragment>
                                      : ""
                                  }
                                >
                                  <Badge
                                    badgeContent={cell.row.original.proroghe?.length}
                                    color="secondary"
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right',
                                    }}
                                  >
                                    <Label color="error">{cell.value}</Label>
                                  </Badge>
                                </Tooltip>
                              )
                          }
                        },
                        Filter: SelectColumnFilter,
                        filter: SelectColumnFilterMethod,
                      },
                      {
                        Header: 'Certificato',
                        accessor: "certificato",
                        Cell: cell => (
                          cell.value ?
                            <Label color="success"><Check fontSize="small" className={classes.labelIcon} /></Label> :
                            <Label color="warning"><X className={classes.labelIcon} /></Label>
                        ),
                        width: 120,
                        disableFilters: true
                      },
                      {
                        id: 'azioni',
                        disableFilters: true,
                        disableSortBy: true,
                        Cell: row => {
                          return (
                            <>
                              {row.row.original.idAudio && play === row.row.original.idAudio ?
                                <Box sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  padding: '10px'
                                }}>
                                  <CircularProgress size='1.5rem' />
                                </Box> :
                                <IconButton
                                  disabled={!row.row.original.idAudio || play.length > 0}
                                  onClick={() => playAudio({ id: row.row.original.idAudio })}>
                                  <RecordVoiceOverIcon fontSize="small" />
                                </IconButton>
                              }
                              <PermissionGuard resource="creaRichiesta" action="allow">
                                <IconButton
                                  onClick={async () => {
                                    let res = await deleteRichiesta({ id: row.row.original.id })
                                    if (res.error) {
                                      enqueueSnackbar(("Impossibile eliminare la richiesta"), {
                                        anchorOrigin: {
                                          horizontal: 'right',
                                          vertical: 'top'
                                        },
                                        variant: 'error'
                                      })
                                    } else {
                                      enqueueSnackbar(("Richiesta rimossa"), {
                                        anchorOrigin: {
                                          horizontal: 'right',
                                          vertical: 'top'
                                        },
                                        variant: 'success'
                                      })
                                    }
                                  }}>
                                  <Trash fontSize="small" color="secondary" />
                                </IconButton>
                              </PermissionGuard>
                            </>
                          )
                        }
                      },
                    ]}
                />
              </Scrollbar>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default PaginaRichieste;
