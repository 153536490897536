import {combineReducers} from '@reduxjs/toolkit';
import authentication from '../pages/login/redux.login';
import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session'
import persistReducer from "redux-persist/es/persistReducer";
import richieste from '../pages/richieste/redux.richieste'
import dipendenti from '../pages/dipendenti/redux.dipendenti'
import users from '../pages/users/redux.user'
import roles from '../pages/roles/redux.role'
import tablePref from 'src/components/Table/redux.table'
// YEOMAN ANCHOR IMPORT */

const authPersistConfig = {
    key: 'auth',
    storage: storageSession,
    blacklist: ['tokenRefreshed', 'refreshingToken']
}

// Aggiungere reducers qui
const rootReducer = combineReducers({
    auth: persistReducer(authPersistConfig, authentication),
    tablePref,
    richieste: persistReducer({key: 'richieste', storage: storageSession}, richieste),
    dipendenti: persistReducer({key: 'dipendenti', storage: storageSession}, dipendenti),
    users: persistReducer({key: 'users', storage: storageSession}, users),
    roles: persistReducer({key: 'roles', storage: storageSession}, roles)
    // YEOMAN ANCHOR REDUCER */
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['auth', 'dipendenti', 'richieste', 'users', 'roles'],
    debug: true
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export default persistedReducer;
