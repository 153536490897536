import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'richieste',
    initialState: {
        data: []
    },
    reducers: {
        readedAll: (state, action) => {
            state.data = action.payload;
        },
        created: (state, action) => {
            state.data = state.data.concat(action.payload);
        },
        deleted: (state, action) => {
            let filtered = state.data.filter(el => { return el.id != action.payload.id });
            state.data = filtered;
        }
    },
})

export const actions = slice.actions;
export default slice.reducer;
