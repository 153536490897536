import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {
  Box, Breadcrumbs,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Link,
  Tab,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@material-ui/core';
import { Table, DatePickerRangeFilter, DatePickerRangeFilterMethod, SelectColumnFilter, SelectColumnFilterMethod } from 'src/components/Table/Table'
import Scrollbar from '../../material/Scrollbar';
import { Helmet } from "react-helmet-async";
import ChevronRightIcon from "../../icons/ChevronRight";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import useSettings from "../../hooks/useSettings";
import dayjs from 'dayjs';
import Label from 'src/material/Label';
import { logAccessi, logSeq, sequenze } from './commands.logs';
import Refresh from 'src/icons/Refresh';

const pageTabs = [
  { label: 'Sequenze', value: 'seq' },
  { label: 'File Inoltrati', value: 'file' },
];

const PaginaLogSequenze: FC = (props) => {
  const [logs, setLogs] = useState([])
  const [files, setFiles] = useState([])
  const [currentTab, setCurrentTab] = useState<string>('seq');
  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  const { settings } = useSettings();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { ...other } = props;

  const subRow = useCallback(
    ({ row }) => {
      return (
        <Card sx={{ width: 'fit-content' }} variant='outlined'>
          <TableHead>
            <TableCell>
              Numero
            </TableCell>

            <TableCell>
              CID
            </TableCell>

            <TableCell>
              Data Inizio
            </TableCell>

            <TableCell>
              Data Fine
            </TableCell>

            <TableCell>
              Data Origine
            </TableCell>

            <TableCell>
              Certificato
            </TableCell>
          </TableHead>
          <TableRow>
            <TableCell sx={{ borderBottom: 'none' }}>
              {row.original?.richiesta.numero}
            </TableCell>

            <TableCell sx={{ borderBottom: 'none' }}>
              {row.original?.richiesta.dipendente.cid}
            </TableCell>

            <TableCell sx={{ borderBottom: 'none' }}>
              {dayjs.unix(row.original?.richiesta.inizio).format("DD/MM/YYYY")}
            </TableCell>

            <TableCell sx={{ borderBottom: 'none' }}>
              {dayjs.unix(row.original?.richiesta.fine).format("DD/MM/YYYY")}
            </TableCell>

            <TableCell sx={{ borderBottom: 'none' }}>
              {dayjs.unix(row.original?.richiesta.origine).format("DD/MM/YYYY")}
            </TableCell>

            <TableCell sx={{ borderBottom: 'none' }}>
              {row.original?.richiesta.certificato ? "✅" : "❌"}
            </TableCell>
          </TableRow>
        </Card>
      )
    }, [])

  const loadData = () => {
    const loadLogs = async () => {
      const res = await sequenze()
      if (res.error) {
        console.error(res.message)
        return
      }

      setLogs(res.data)
    }

    const loadFiles = async () => {
      const res = await logSeq()
      if (res.error) {
        console.error(res.message)
        return
      }

      setFiles(res.data)
    }

    loadLogs()
    loadFiles()
  }


  useEffect(() => {
    loadData()
  }, [])

  return (
    <>
      <Helmet>
        <title>Dashboard: Logs </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Registro Sequenze
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Log Sequenze
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={loadData}
                startIcon={<Refresh />}
              >
                Aggiorna
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Card {...other}>
              <Tabs
                indicatorColor="primary"
                onChange={handleTabsChange}
                scrollButtons="auto"
                textColor="primary"
                value={currentTab}
                variant="scrollable"
              >
                {pageTabs.map((tab) => (
                  <Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                  />
                ))}
              </Tabs>
              {currentTab === 'seq' &&
                <Scrollbar>
                  <Box sx={{ minWidth: 1200 }}>
                    <Table
                      idDefaultColumnSort='createdAt'
                      Data={logs ?? []}
                      RenderRowSubComponent={subRow}
                      Columns={
                        [
                          {
                            Header: () => null,
                            id: 'expander',
                            width: 50,
                            Cell: ({ row }) => (
                              <span {...row.getToggleRowExpandedProps()}>
                                {row.isExpanded ? <IconButton><KeyboardArrowDownIcon fontSize="small" /></IconButton> : <IconButton><KeyboardArrowUpIcon fontSize="small" /></IconButton>}
                              </span>
                            ),
                          },
                          {
                            Header: 'Content',
                            accessor: 'content',
                          },
                          {
                            Header: 'Data',
                            accessor: 'createdAt',
                            Filter: DatePickerRangeFilter,
                            filter: DatePickerRangeFilterMethod,
                            Cell: cell => (cell.value ? dayjs.unix(cell.value).format('DD/MM/YYYY HH:mm') : null),
                          },
                          {
                            Header: 'Stato',
                            accessor: cell => (cell.sent ? "Inoltrata" : "In Attesa"),
                            Filter: SelectColumnFilter,
                            filter: SelectColumnFilterMethod,
                            Cell: cell => (cell.row.original.sent ? <Label color="success">Inoltrata</Label> : <Label color="warning">In Attesa</Label>),
                          },
                          {
                            Header: 'Inoltrata il',
                            accessor: 'sentAt',
                            Filter: DatePickerRangeFilter,
                            filter: DatePickerRangeFilterMethod,
                            Cell: cell => (cell.value ? dayjs.unix(cell.value).format('DD/MM/YYYY HH:mm') : null),
                          },
                        ]}
                    />
                  </Box>
                </Scrollbar>
              }

              {currentTab === 'file' &&
                <Scrollbar>
                  <Box sx={{ minWidth: 1200 }}>
                    <Table
                      Data={files ?? []}
                      Columns={
                        [
                          {
                            Header: 'Content',
                            accessor: 'content',
                            Cell: cell => (
                              <pre>
                                {cell.value}
                              </pre>
                            )
                          },
                          {
                            Header: 'Data',
                            accessor: 'createdAt',
                            Filter: DatePickerRangeFilter,
                            filter: DatePickerRangeFilterMethod,
                            Cell: cell => (cell.value ? dayjs.unix(cell.value).format('DD/MM/YYYY HH:mm') : null),
                          },
                        ]}
                    />
                  </Box>
                </Scrollbar>
              }
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  )
    ;
};

export default PaginaLogSequenze;
