import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  useTable,
  usePagination,
  useFilters,
  useExpanded,
  useFlexLayout,
  useResizeColumns,
  useSortBy
} from 'react-table';
import { matchSorter } from 'match-sorter'
import moment from 'moment';
import MaUTable from '@material-ui/core/Table'
import {
  Select,
  MenuItem,
  Chip,
  createStyles,
  IconButton,
  Theme,
  TextField,
  Button,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Typography,
  Grid,
  Divider,
  makeStyles,
  FormControlLabel,
  Switch,
  Box,
  Skeleton,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { actions, TableFiltersValues, TableOrder, TablePreferences } from './redux.table'
import ClearAllIcon from '@material-ui/icons/ClearAll';

import { useTheme } from '@material-ui/core/styles';


import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css';
import 'moment/locale/it'
import { useTranslation } from 'react-i18next';
import { settings } from 'nprogress';
import useSettings from 'src/hooks/useSettings';
import store, { useSelector } from 'src/store';
import { useLocation } from 'react-router';
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { FilterInput } from 'src/types/generated';
import { useAsyncDebounce } from 'react-table'

const Styles = styled.div`  
.CalendarDay__selected_span {
  background: #4e75e1;
  color: white;
  border: 1px solid;
}


.CalendarDay__selected {
  background: #27293d;
  color: white;
}


.CalendarDay__selected:hover {
  background: white;
  color: black;
}


.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #4e75e1;
  color: #ffffff;
}

.CalendarDay__hovered_span_3 {
  color: #ffffff;
}

.DateRangePicker {
  border-radius: ${props => props.rounded}px;
  border: 1px solid #80808073 !important;
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}


.DateRangePicker:hover {
  border-color: black !important;
}


.DateRangePicker div {
  border-radius: 0.4285rem;


}

.DateRangePicker_picker {
  z-index: 99999 !important;

}

.DateRangePickerInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 0;
  border-radius: 0.4285rem;
  background-color: inherit;
  height: 38px;
}

.DateInput {
  margin: 0;
  padding: 0;
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  color: #ffffff;
  height: 100%;
  background-color: inherit;
}

.DateInput_input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-weight: 400;
  font-size: 1rem;
  border: 0px;
  text-align: center;
  box-shadow: none;
  width: 100%;
  height: 100%;
  padding: 4px 0px 5px;
  border-radius: ${props => props.rounded}px;
  color: #919eab;
  text-decoration-color: #000000;
  background-color: inherit;
}

.DateInput_input::placeholder {
  color: #6b778c
}

.DateRangePickerInput__withBorder {
  color: #ffffff;
}

DateRangePicker_picker__fullScreenPortal {
  background: linear-gradient(0deg, #3358f4 0%, #1d8cf8 100%);
}

.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px;

}

.DateRangePickerInput_arrow {
  display: flex;
  align-items: center;
  color: grey;
  width: 28px
}

.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  height: 24px;
  width: 24px
}

.CalendarDay>td {
  height: 10px;
  width: 10px;
}
    .pagination {
      padding: 16px;
      width: 70%;
    }
    .filter {
      width: 90%;
      marginTop: 8px;
      padding: 5px;
    }
    .paginationSelect:before{
      border-bottom: 0px;
      transition: none;
      content: none;
    }
    .paginationSelect:after{
      border-bottom: 0px;
    }
    .selectmenu > div:focus{
      background-color: inherit !important;
    }

    .resizer {
      display: inline-block;
      background: none;
      width: 3px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(50%);
      z-index: 1;
      ${'' /* prevents from scrolling while dragging on touch devices */}
      touch-action:none;

      &.isResizing {
        background: white;
      }
    }

    .resizer:hover{
      background: grey;
    }

   .iconSort{
     fill: lightgrey
   }

   .iconSort:hover{
     fill: grey
   }

  `
const DarkStyles = styled.div`  
  .CalendarDay__selected_span {
    background: #4e75e1;
    color: white;
    border: 1px solid;
  }

  .CalendarDay__selected {
    background: #27293d;
    color: white;
  }

  .CalendarDay__selected:hover {
    background: white;
    color: black;
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: #4e75e1;
    color: #ffffff;
  }

  .CalendarDay__hovered_span_3 {
    color: #ffffff;
  }

  .DateRangePicker {
    border-radius: ${props => props.rounded}px;
    border: 1px solid rgb(79, 86, 95) !important;
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }



  .DateRangePicker:hover {
    border-color: #ffffff !important;
  }

  .DateRangePicker_picker {
    z-index: 99999 !important;
  }

  .DateRangePickerInput {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 0;
    background-color: inherit;
    height: 38px;
  }

  .DateInput {
    margin: 0;
    padding: 0;
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    color: #ffffff;
    height: 100%;
    background-color: inherit;
  }

  .DateInput_input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-weight: 400;
    font-size: 1rem;
    border: 0px;
    text-align: center;
    box-shadow: none;
    width: 100%;
    height: 100%;
    padding: 4px 0px 5px;
    border-radius: ${props => props.rounded}px;
    color: #919eab;
    text-decoration-color: #000000;
    background-color: inherit;
  }

  .DateInput_input::placeholder {
    color: #919eab
  }

  .DateRangePickerInput__withBorder {
    color: #ffffff;
  }

  DateRangePicker_picker__fullScreenPortal {
    background: linear-gradient(0deg, #3358f4 0%, #1d8cf8 100%);
  }

  .DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px;

  }

  .DateRangePickerInput_arrow {
    display: flex;
    align-items: center;
    color: #ffffff52 !important;
    width: 28px
  }

  .DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    height: 24px;
    width: 24px
  }

  .CalendarDay>td {
    height: 10px;
    width: 10px;
  }

  .pagination {
    padding: 16px;
    display: flex;   
  }
  
  .paginationSelect{
    color: white;
    border: none;
  }

  .paginationSelect:before{
    border-bottom: 0px;
    transition: none;
    content: none;
  }
  
  .paginationSelect:after{
    border-bottom: 0px;
  }
  
  .filter {
    width: 90%;
    marginTop: 8px;
    padding: 5px;   
  }
 
  .icon {
    fill: white;
  }

  .paginationSelect > svg {
    fill: white;
  }

  .selectmenu > div {
    color: white;
  }
  
  .selectmenu > svg {
    fill: white;
  }  

  .resizer {
    display: inline-block;
    background: none;
    width: 3px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    ${'' /* prevents from scrolling while dragging on touch devices */}
    touch-action:none;

    &.isResizing {
      background: white;
    }      
  }

  .resizer:hover{
    background: white;
  }

 .iconSort{
   fill: #9c9c9c
 }

 .iconSort:hover{
   fill: white
 }
  
`

function DefaultColumnFilter({
  column: { filterValue, setFilter },
}) {
  const { t } = useTranslation()
  const [value, setValue] = useState(filterValue)
  const onChange = useAsyncDebounce(value => {
    setFilter(value || undefined)
  }, 500)
  return (
    <TextField
      fullWidth
      size='small'
      value={value}
      onChange={e => {
        setValue(e.target.value)
        onChange(e.target.value || undefined)
      }}
      placeholder={t("search")}
      variant='outlined'
    />
  )
}

function isSameDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  // Compare least significant, most likely to change units first
  // Moment's isSame clones moment inputs and is a tad slow
  return a.date() === b.date()
    && a.month() === b.month()
    && a.year() === b.year();
}

export function DatePickerRangeFilter({
  column: {
    filterValue,
    preFilteredRows,
    setFilter,
    id
  }
}) {

  const { t } = useTranslation()
  const { i18n } = useTranslation()

  new Date();
  var momDate = moment();
  var firstDay = moment().startOf('month');
  var lastDay = moment().endOf('month');
  var firstWeekDay = moment().startOf('week');
  var lastWeekDay = moment().endOf('week');
  var previousFirstDay = moment().startOf('month').subtract(1, 'month');
  var previousLastDay = moment().subtract(1, 'month').endOf('month');
  var nextFirstDay = moment().startOf('month').add(1, 'month');
  var nextLastDay = moment().add(1, 'month').endOf('month');
  moment().startOf('year');
  moment().endOf('year');
  var yesterday = moment().subtract(1, 'days');
  var tomorrow = moment().add(1, 'days');

  var presetOpt = {
    startDate: null,
    endDate: null,
    presets: [
      {
        label: t("today"),
        startDate: momDate,
        endDate: momDate
      },
      {
        label: t("yesterday"),
        startDate: yesterday,
        endDate: yesterday
      },
      {
        label: t("tomorrow"),
        startDate: tomorrow,
        endDate: tomorrow
      },
      {
        label: t("week"),
        startDate: firstWeekDay,
        endDate: lastWeekDay
      },
      {
        label: t("month"),
        startDate: firstDay,
        endDate: lastDay
      },
      {
        label: t("lastMonth"),
        startDate: previousFirstDay,
        endDate: previousLastDay
      },
      {
        label: t("nextMonth"),
        startDate: nextFirstDay,
        endDate: nextLastDay
      },
    ],
  };

  const [startDate, setStartDate] = useState<moment.Moment>(null);
  const [endDate, setEndDate] = useState<moment.Moment>(null);
  const [focusedInput, setFocusedInput] = useState(false);

  useEffect(() => {
    moment.locale(i18n.language)

    if (filterValue) {
      setStartDate(filterValue.start === '0000/00/00' ? null : moment(filterValue.start))
      setEndDate(filterValue.end === '9999/99/99' ? null : moment(filterValue.end))
      return
    }

    setStartDate(null)
    setEndDate(null);
  }, [t, filterValue])


  const renderDatePresets = () => {
    return (
      <div>
        {presetOpt.presets.map(({ label, startDate, endDate }) => {
          const isSelected = isSameDay(presetOpt.startDate, startDate) && isSameDay(presetOpt.endDate, endDate);
          let className = '';
          if (!isSelected) {
            className = "btn-simple";
          }
          return (
            <Button size="small" className={className} color="primary" variant='text'
              key={label}
              onClick={() => {
                onDatesChange({ startDate: startDate, endDate: endDate });
                setFocusedInput(false);
              }}>
              {label}
            </Button>
          );
        })}
      </div>
    );
  }

  const onDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate != null && endDate != null) {
      setFilter({
        start: moment(startDate).format('YYYY/MM/DD'),
        end: moment(endDate).format('YYYY/MM/DD')
      });
    }
  };

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center'
        }}
      >
        <DateRangePicker
          startDatePlaceholderText={t("startDate")}
          endDatePlaceholderText={t("endDate")}
          startDate={startDate}
          onDatesChange={onDatesChange}
          startDateId={"startDate"}
          endDate={endDate}
          renderCalendarInfo={renderDatePresets}
          preset
          minimumNights={0}
          hideKeyboardShortcutsPanel
          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
          isOutsideRange={() => false}
          customArrowIcon={<KeyboardArrowRight style={{ padding: '4px' }} />}
        />
        <IconButton
          onClick={
            () => {
              setStartDate(null)
              setEndDate(null);
              setFilter({
                start: '0000/00/00',
                end: '9999/99/99'
              })
            }
          }
          size="small"
          className='iconFilterDate'
        >
          <ClearIcon />
        </IconButton>
      </Box>
    </>
  )
}

function formatDate(input) {
  var datePart = input.match(/\d+/g),
    year = datePart[0],
    month = datePart[1],
    day = datePart[2];

  return day + '/' + month + '/' + year;
}

function compareDate(start, end, checkdate) {
  let D_start = start.split("/");
  let D_end = end.split("/");
  let D_check = checkdate.split("/");

  //console.log("sono oltre",D_start, " ",D_end," ",D_check )

  var d_start = new Date(D_start[2], parseInt(D_start[1]) - 1, D_start[0]);
  var d_end = new Date(D_end[2], parseInt(D_end[1]) - 1, D_end[0]);
  var d_check = new Date(D_check[2], parseInt(D_check[1]) - 1, D_check[0]);

  if (d_check >= d_start && d_check <= d_end) {
    return true;
  } else {
    return false;
  }
}

export function DatePickerRangeFilterMethod(rows, id, filterValue) {
  var start = formatDate(filterValue.start);
  var end = formatDate(filterValue.end);

  return rows.filter(row => {
    return compareDate(start, end, moment.unix(row.original[id]).format("DD/MM/YYYY"))
  })
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }),
);

export function GetColumnWidth(rows, accessor, headerText) {
  const maxWidth = 400
  const magicSpacing = 10
  const cellLength = Math.max(
    ...rows.map(row => (`${row[accessor]}` || '').length),
    headerText.length,
  )
  return Math.min(maxWidth, cellLength * magicSpacing)
}

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
  remoteFilterOptions
}) {
  const { t } = useTranslation()
  const options = React.useMemo(() => {
    if (remoteFilterOptions?.length > 0) {
      let rv: string[]
      remoteFilterOptions.forEach(item => {
        if (item.column === id) {
          rv = item.options
        }
      })

      return rv
    }

    const options = new Set()
    preFilteredRows.forEach(row => {
      if (row.values[id]) {
        options.add(row.values[id])
      }
    })
    return [...options.values()]
  }, [id, preFilteredRows, remoteFilterOptions]) as string[]

  const [selezionati, setSelezionati] = React.useState<string[]>(filterValue ?? []);

  const handleChange = (event) => {
    setSelezionati(event.target.value);
  };

  useEffect(() => {
    setSelezionati(filterValue)
  }, [filterValue])

  return (
    <>
      <Select
        multiple
        fullWidth
        displayEmpty
        size="small"
        variant="outlined"
        value={selezionati ?? []}
        onChange={handleChange}
        onClose={() => {
          if (selezionati) {
            setFilter(selezionati)
          }
        }}
        renderValue={(selezionati) => {
          if (selezionati?.length === 0) {
            return <Typography color='textSecondary' >{t("select")}</Typography>;
          }

          return <Typography variant="overline">{selezionati.join(', ')}</Typography>;
        }}
      >
        <MenuItem value="" disabled>
          <em>{t("select")}</em>
        </MenuItem>
        {options.map((option, i) => (
          <MenuItem key={i} value={option}>
            <Typography variant="overline">{option}</Typography>
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

export function SelectColumnFilterMethod(rows, id, filterValue) {
  let righeFiltrate = [];
  if (filterValue.length) {
    for (let i = 0; i < filterValue.length; i++) {
      for (let j = 0; j < rows.length; j++) {
        if (filterValue[i] === rows[j].values[id]) {
          righeFiltrate.push(rows[j])
        }
      }
    }
    return righeFiltrate;
  } else {
    return rows
  }
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
export function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  const { t } = useTranslation()
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <TextField
        value={filterValue[0] || ''}
        size="small"
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
        }}
        label={`Min (${min})`}
        sx={{ mr: '5px' }}
      />
      {t("to")}
      <TextField
        value={filterValue[1] || ''}
        size="small"
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
        }}
        label={`Max (${max})`}
        sx={{ ml: '5px' }}
      />
    </Box>
  )
}
function fuzzyTextFilterFn(rows, id, filterValue) {
  // @ts-ignore
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

const getStyles = (props, align = 'left') => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
    },
  },
]

function TableStruct({
  columns,
  data,
  renderRowSubComponent,
  idcolonna,
  FiltersRemote,
  disableFilters,
  disableSorting,
  pageCount: controlledPageCount,
  fetchData,
  loading,
  selectFilterColumns,
  selectFilterOptions
}) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      array: (rows, id, filterValue) => {
        // @ts-ignore
        return matchSorter(rows, filterValue, { keys: [row => row.values[id]?.map(rule => rule?.description)] })
      },
      data: (rows, id, filterValue) => {
        // @ts-ignore
        return matchSorter(rows, filterValue, { keys: [row => moment(new Date(row.values[id])).format("DD/MM/YYYY")] })
      },
    }),
    []
  )
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      minWidth: 50,
      maxWidth: 600,
    }),
    []
  )
  const location = useLocation();
  const prefs = useSelector(state => state.tablePref)
  let tablePref = prefs.data.filter(obj => obj.table === location.pathname)[0] as TablePreferences

  if (!tablePref) {
    tablePref = {
      page: 0,
      orders: [{
        id: idcolonna,
        desc: true
      }],
      table: location.pathname,
      maxRows: 10,
      filters: {
        enabled: false,
        values: [] as TableFiltersValues[]
      }
    }

    store.dispatch(actions.add(tablePref))
  }

  let config = {
    manualPagination: fetchData ? true : false,
    manualFilters: fetchData ? true : false,
    autoResetExpanded: false,
    columns,
    data,
    initialState: {
      pageIndex: tablePref.page,
      pageSize: tablePref.maxRows,
      autoResetExpanded: false,
      sortBy: tablePref.orders,
      filters: tablePref.filters.enabled ? tablePref.filters.values : []
    }, // Pass our hoisted table state
    defaultColumn, // Be sure to pass the defaultColumn option
    filterTypes,
    autoResetPage: false,
    autoResetFilters: false,
    autoResetResize: false,
    sortTypes: {
      alphanumeric: (row1, row2, columnName) => {
        const rowOneColumn = row1.values[columnName];
        const rowTwoColumn = row2.values[columnName];
        if (Array.isArray(rowOneColumn)) { //ordinamento per gli array fatto sul primo elemento
          //assegno z per quelli vuoti cosi da farli apparire in fondo
          const rowOneColArr = row1.values[columnName][0] != undefined ? row1.values[columnName][0] : "z"
          const rowTwoColArr = row2.values[columnName][0] != undefined ? row2.values[columnName][0] : "z"
          if (isNaN(rowOneColArr) && rowOneColArr != 0 && rowOneColArr != undefined && rowTwoColArr != undefined) {
            return rowOneColArr.toUpperCase() > rowTwoColArr.toUpperCase() ? 1 : -1;
          } else {
            return Number(rowOneColArr) > Number(rowTwoColArr) ? 1 : -1;
          }
        }
        if (isNaN(rowOneColumn) && rowOneColumn != 0) {
          return rowOneColumn.toUpperCase() > rowTwoColumn.toUpperCase() ? 1 : -1;
        } else {
          return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
        }
      }
    }
  }

  // conditionally add pageCount
  config = {
    ...(controlledPageCount) && { pageCount: controlledPageCount },
    ...config
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    nextPage,
    pageCount,
    gotoPage,
    previousPage,
    setPageSize,
    visibleColumns,
    setAllFilters,
    // Get the state from the instance
    state: { pageIndex, pageSize, expanded, filters },
  } = useTable(
    config,
    useResizeColumns,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
  )

  const handleIsSorted = (column) => {
    return (
      column.isSorted
        ? column.isSortedDesc
          ? <IconButton
            size='small'
            onClick={() => setColumnSorted({ id: idcolonna ?? "", desc: false })}
          >
            <ArrowDownwardIcon fontSize="small" />
          </IconButton>
          : <IconButton
            size='small'
            onClick={() => setColumnSorted({ id: column.id, desc: true })}
          >
            <ArrowUpwardIcon fontSize="small" />
          </IconButton>
        : <IconButton
          size='small'
          onClick={() => setColumnSorted({ id: column.id, desc: false })}
        >
          <SortIcon
            className='iconSort'
            fontSize="small"
          />
        </IconButton>
    )
  }

  const { t } = useTranslation()
  let filtri = FiltersRemote;
  const [columnSorted, setColumnSorted] = useState<TableOrder>(tablePref.orders[0])
  const [filtersMemo, setFiltersMemo] = useState(tablePref.filters.enabled)
  useEffect(() => {
    tablePref = {
      page: pageIndex,
      orders: [columnSorted],
      table: location.pathname,
      maxRows: pageSize,
      filters: {
        enabled: filtersMemo,
        values: filters
      }
    }

    if (fetchData) {
      let remoteFilters = [] as FilterInput[]
      filters.map((filter) => {
        let remoteFilter: FilterInput
        if (filter.value.start) {
          remoteFilter = {
            column: filter.id,
            value: [JSON.stringify(filter.value)],
            type: 'date'
          }
          remoteFilters.push(remoteFilter)
        } else if (Array.isArray(filter.value)) {
          remoteFilter = {
            column: filter.id,
            value: filter.value,
            type: 'select'
          }
          remoteFilters.push(remoteFilter)
        } else {
          remoteFilter = {
            column: filter.id,
            value: [filter.value],
            type: 'text'
          }
          remoteFilters.push(remoteFilter)
        }
      })
      fetchData(
        pageSize,
        pageIndex,
        `${tablePref.orders[0].id} ${tablePref.orders[0].desc ? "DESC" : "ASC"}`,
        remoteFilters,
        selectFilterColumns ?? [],
      )
    }
    store.dispatch(actions.update(tablePref))
  }, [pageIndex, columnSorted, pageSize, filters, filtersMemo])

  const getPaginationInfo = () => {
    if (page.length < 10 && page.length != 0 && pageIndex === 0) {
      let output = "1 - " + page.length
      return output
    } else if (page.length === 0) {
      return "0 - 0"
    } else {
      let output = (pageSize * (pageIndex)) + 1 + "-" + ((pageSize * (pageIndex)) + page.length)
      return output
    }
  }

  return (
    <React.Fragment>
      <MaUTable {...getTableProps()} style={{ minHeight: '600px' }}>
        <TableHead>
          {/* <Box
            padding={2}
            display='flex'
            justifyContent='flex-end'
          >
            <Button
              sx={{ marginRight: 3 }}
              variant='outlined'
              size='small'
              onClick={() => setAllFilters([])}
              startIcon={<ClearAllIcon />}
            >
              {<Typography variant="overline">{t("resetFilters")}</Typography>}
            </Button>
            <FormControlLabel
              control={
                <Switch
                  checked={filtersMemo}
                  onChange={() => setFiltersMemo(prev => !prev)}
                  name="setFiltersMemo"
                  color="primary"
                  size="small"
                />
              }
              label={<Typography variant="overline">{t("memoFilters")}</Typography>}
            />
          </Box> */}
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <TableCell {...column.getHeaderProps()}>
                  <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Box
                      style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                    >
                      <Typography variant="overline">{column.render('Header')}</Typography>
                    </Box>
                    {column.canSort && !disableSorting &&
                      <Box
                        {...column.getHeaderProps(column.getSortByToggleProps({ title: 'Ordina' }))}
                        style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                      >
                        {handleIsSorted(column)}
                      </Box>
                    }

                    {!column.canSort &&
                      <Box
                        {...column.getHeaderProps(column.getSortByToggleProps({ title: '' }))}
                        style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '44px', width: '44px' }}
                      >
                      </Box>
                    }

                    {disableSorting &&
                      <Box
                        {...column.getHeaderProps(column.getSortByToggleProps({ title: '' }))}
                        style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '44px', width: '44px' }}
                      >
                      </Box>
                    }
                  </Box>
                  {filtri ? <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '10px' }}>{filtri[index]}</Box> :
                    disableFilters ? null : <Box style={{ width: '100%', display: 'flex' }}>
                      <Box style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginTop: '5px'
                      }}>
                        {column.canFilter ? column.render('Filter', { remoteFilterOptions: selectFilterOptions ?? null }) : null}
                      </Box>
                    </Box>
                  }
                  <Box {...column.getResizerProps()}
                    className={`resizer ${column.isResizing ? 'isResizing' : ''}`}>
                  </Box>

                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {
            page.length > 0 ?
              page.map((row, i) => {
                prepareRow(row)
                return (
                  <React.Fragment>
                    <TableRow {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <TableCell {...cell.getCellProps()} >
                            {
                              loading ?
                                <Skeleton animation='wave' /> :
                                <Box style={{
                                  display: "flex",
                                  alignItems: "center",
                                  height: "100%",
                                }}>
                                  {cell.render('Cell')}
                                </Box>
                            }
                          </TableCell>
                        )
                      })}
                    </TableRow>

                    {row.isExpanded ? (
                      <TableRow {...row.getRowProps()}>
                        <TableCell colSpan={visibleColumns.length}>
                          {renderRowSubComponent({ row })}
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </React.Fragment>
                )
              }) : (
                <Box style={{
                  height: '400px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <Typography
                    variant='body1'
                  >
                    {t("tableNoData")}
                  </Typography>

                </Box>
              )
          }
        </TableBody>

        <TableFooter>
          <TableRow>
            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Box component='span' style={{ padding: '10px' }}>
                <Typography variant='overline'>
                  {t("view")}
                </Typography>
              </Box>
              <FormControl variant="standard" size="small">
                <Select
                  value={pageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value))
                  }}
                >
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <MenuItem key={pageSize} value={pageSize}>{pageSize}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box style={{ padding: '10px' }}>
                <Typography variant='overline'>
                  {t("page")} {pageIndex + 1} {t("of")} {controlledPageCount ?? Math.ceil(rows.length / pageSize)}
                </Typography>
              </Box>

              <IconButton
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                aria-label="delete"
                size="small"
              >
                <FirstPageIcon className='icon' fontSize="inherit" />
              </IconButton>

              <IconButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                aria-label="delete"
                size="small"
              >
                <KeyboardArrowLeft className='icon' fontSize="inherit" />
              </IconButton>

              <IconButton onClick={() => nextPage()}
                disabled={!canNextPage} aria-label="delete" size="small">
                <KeyboardArrowRight className='icon' fontSize="inherit" />
              </IconButton>

              <IconButton
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                aria-label="delete"
                size="small"
              >
                <LastPageIcon className='icon' fontSize="inherit" />
              </IconButton>

              <Box component='span' style={{ padding: '10px' }}>
                <Typography variant='overline'>
                  {getPaginationInfo()} {t("of")} {controlledPageCount ? controlledPageCount * pageSize : rows.length}
                </Typography>
              </Box>
            </Box>
          </TableRow>
        </TableFooter>
      </MaUTable>

    </React.Fragment >
  )
}
export interface TableProps {
  Data?: any;
  Columns?: any;
  RenderRowSubComponent?: any;
  idDefaultColumnSort?: any;
  remoteFilter?: any;
  disableFilters?: boolean;
  disableSorting?: boolean;
  tableName?: string;
  pageCount?: any;
  fetchData?: any;
  loading?: boolean;
  selectFilterColumns?: string[];
  selectFilterOptions?: any;
}

export const Table: React.FC<TableProps> = ({
  Data,
  Columns,
  RenderRowSubComponent,
  idDefaultColumnSort,
  remoteFilter,
  disableFilters,
  disableSorting,
  tableName,
  pageCount,
  fetchData,
  loading,
  selectFilterColumns,
  selectFilterOptions,
  ...props
}) => {
  const theme = useTheme();

  switch (theme.palette.mode) {
    case "dark":
      return (
        <DarkStyles rounded={theme.shape.borderRadius}>
          <TableStruct
            columns={Columns}
            data={Data}
            disableSorting={disableSorting ? true : false}
            disableFilters={disableFilters ? true : false}
            FiltersRemote={remoteFilter}
            renderRowSubComponent={RenderRowSubComponent}
            idcolonna={idDefaultColumnSort}
            pageCount={pageCount}
            fetchData={fetchData}
            loading={loading}
            selectFilterColumns={selectFilterColumns}
            selectFilterOptions={selectFilterOptions}
            {...props}
          />
        </DarkStyles>
      );
    case "light":
      return (
        <Styles rounded={theme.shape.borderRadius}>
          <TableStruct
            columns={Columns}
            data={Data}
            disableSorting={disableSorting ? true : false}
            disableFilters={disableFilters ? true : false}
            FiltersRemote={remoteFilter}
            renderRowSubComponent={RenderRowSubComponent}
            idcolonna={idDefaultColumnSort}
            pageCount={pageCount}
            fetchData={fetchData}
            loading={loading}
            selectFilterColumns={selectFilterColumns}
            selectFilterOptions={selectFilterOptions}
            {...props}
          />
        </Styles>
      );
  }
}